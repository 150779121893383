import { useState, useEffect, Fragment } from "react";
import { TableAntd, Searcher, TitleHeader } from "../../components";
import { createColumns } from "./formatTable";
import { executeQuery, marcarConstanciaNoEntregada } from "../../api/ingresante";
import { notification, Button, Modal } from "antd";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useAuth } from "../../utils/useAuth";
import { filterRepeatedAndReturnUniqueValues } from "../../utils/functions";

function Lista() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const [reload, setReload] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [dataToTable, setDataToTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterTable, setFilterTable] = useState(null);
  const [totalEntregados, setTotalEntregados] = useState(0);

  const fetchDataToTable = async () => {
    setIsLoading(true);
    executeQuery({
      query: "listar_todas_constancias_entregadas"
    })
      .then((res) => {
        setDataToTable(res.payload);
        setTotalData(res.payload.length);
        const constanciasEntregadas = res.payload.filter((c) => c.constanciaentregada === 1);
        setTotalEntregados(constanciasEntregadas.length);
      })
      .catch((err) => {
        notification.error({
          message: "Problemas con la solicitud",
          description: err.message
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataToTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const { usuario } = useAuth();

  const setConstanciaNoEntregada = (record) => {
    Modal.confirm({
      title: `¿Estás seguro de Desmarcar entrega de constancia al ingresante '${record.nombre}?'`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, desmarcar",
      cancelText: "Cancelar",
      onOk() {
        marcarConstanciaNoEntregada({ idingresante: record.idingresante })
          .then((res) => {
            notification.success({
              message: "Solicitud exitosa",
              description: res.message
            });
          })
          .catch((err) => {
            notification.error({
              message: "Problemas con la solicitud",
              description: err.message
            });
          })
          .finally(() => {
            fetchDataToTable();
          });
      }
    });
  };

  const carrerasFilter = filterRepeatedAndReturnUniqueValues(dataToTable, "programaestudio");

  const columnsToTable = createColumns(
    page,
    pageSize,
    setConstanciaNoEntregada,
    usuario,
    carrerasFilter
  );

  const search = (value) => {
    const tempFilterTable = dataToTable.filter((o) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.toLowerCase()))
    );

    setFilterTable(tempFilterTable);
  };

  const calculaPorcentaje = ((totalEntregados * 100) / totalData).toFixed(2);

  return (
    <Fragment>
      <span className="flex gap-2 items-center justify-between">
        <TitleHeader
          title={
            <span className="flex gap-2 items-center">
              <h1 className="font-bold text-lg">
                <span className="text-azul-500">{totalEntregados}</span> &nbsp;Constancias
                entregadas de {totalData} (
                <span className="text-azul-500">{calculaPorcentaje}%</span>) / faltan{" "}
                <span className="text-azul-500">{totalData - totalEntregados}</span>
              </h1>
              <Button
                size="small"
                icon={<ReloadOutlined />}
                onClick={() => setReload(!reload)}
              ></Button>
            </span>
          }
        />

        <Searcher onSearch={search} placeholder="Busca una constancia" />
      </span>

      <TableAntd
        columns={columnsToTable}
        dataSource={filterTable == null ? dataToTable : filterTable}
        setPage={setPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        isLoading={isLoading}
      />
    </Fragment>
  );
}

export default Lista;
