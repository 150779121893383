import React, { useEffect, useState } from "react";
import { Form, Input, message, Button, Select } from "antd";
import { getDataCatalogo } from "../../utils/functions";
import { TIPO_CATALOGO } from "../../utils/shared";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";

const { Option } = Select;

function PersonaAddEdit({ record, closeModal }) {
  const [catalogo, setCatalogo] = useState({
    sexo: [],
    tipo_documento: [],
    estado_civil: [],
    pais: [],
    zona: [],
    via: [],
    departamentos: []
  });

  const history = useHistory();

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  useEffect(() => {
    fetchCatalogo();
  }, []);

  const sendDataToServer = async (data) => {
    const params = {
      nombretabla: "ope_entidad",
      nombreid: "identidad",
      valorid: record?.identidad
    };

    AppApi.executeUpdate(params, data)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        closeModal();
        history.replace("/app/persona");
      });
  };

  const fetchCatalogo = async () => {
    const tipo_documento = await getDataCatalogo(TIPO_CATALOGO.tipo_documento);
    const sexo = await getDataCatalogo(TIPO_CATALOGO.sexo);
    const estado_civil = await getDataCatalogo(TIPO_CATALOGO.estado_civil);
    const pais = await getDataCatalogo(TIPO_CATALOGO.pais);
    const zona = await getDataCatalogo(TIPO_CATALOGO.zona);
    const via = await getDataCatalogo(TIPO_CATALOGO.via);

    setCatalogo({
      tipo_documento,
      sexo,
      estado_civil,
      pais,
      zona,
      via
    });
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        <Form.Item
          label="Nombre"
          name="nombre"
          initialValue={record?.nombre}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Apellido paterno"
          name="apellidopaterno"
          initialValue={record?.apellidopaterno}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Apellido materno"
          name="apellidomaterno"
          initialValue={record?.apellidomaterno}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tipo de documento"
          name="idtipodocumentoregistro"
          initialValue={record?.idtipodocumentoregistro || ""}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.tipo_documento.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Número de documento"
          name="documentoregistro"
          initialValue={record?.documentoregistro}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Ubigeo" name="idlugarubigeo" initialValue={record?.idlugarubigeo}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Sexo"
          name="idsexo"
          initialValue={record?.idsexo || ""}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.sexo.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Estado civil"
          name="idestadocivil"
          initialValue={record?.idestadocivil || ""}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.estado_civil.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Fecha de nacimiento"
          name="fechanacimiento"
          initialValue={record?.fechanacimiento}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input type="date" />
        </Form.Item>

        <Form.Item
          label="Correo electrónico"
          name="email"
          initialValue={record?.email}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label="celular"
          name="celular"
          initialValue={record?.celular}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}

export default PersonaAddEdit;
