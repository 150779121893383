import React from "react";
import moment from "moment";
import { viewFileByUrl } from "../../utils/functions";

function PersonaDetail({ persona, postulaciones }) {
  return (
    <div className="w-full h-full">
      <div className="bg-gray-100">
        <div className="container mx-auto my-5 p-5">
          <div className="md:flex no-wrap md:-mx-2 ">
            <div className="w-full md:w-3/12 md:mx-2">
              <div
                className={
                  "bg-white p-3 border-t-4 " +
                  (Boolean(persona?.activo) ? "border-green-400" : "border-rojo-400")
                }
              >
                <div className="text-center my-2">
                  <img
                    className="h-32 w-28 rounded-full mx-auto"
                    src={viewFileByUrl(
                      "/" + persona?.identidad + "/" + persona?.documentoregistro + "_FTR.jpg"
                    )}
                    alt=""
                  />
                  <h1 className="text-gray-900 font-bold text-xl leading-8 my-1">
                    {persona?.nombre}
                  </h1>
                  <p className="text-gray-500">
                    {persona?.apellidopaterno} {persona?.apellidomaterno}
                  </p>
                </div>

                <ul className="bg-gray-100 text-gray-600 hover:text-gray-700 hover:shadow py-2 px-3 mt-3 divide-y rounded shadow-sm">
                  <li className="flex items-center py-3">
                    <span>Estado</span>
                    <span className="ml-auto">
                      <span
                        className={
                          "py-1 px-2 rounded text-white text-sm " +
                          (Boolean(persona?.activo) ? "bg-green-500" : "bg-rojo-500")
                        }
                      >
                        {Boolean(persona?.activo) ? "Activo" : "Desactivado"}
                      </span>
                    </span>
                  </li>
                  <li className="flex items-center py-3">
                    <span>Fecha de registro</span>
                    <span className="ml-auto">{moment(persona?.fechaingreso).format("LL")}</span>
                  </li>
                </ul>
              </div>

              <div className="my-4"></div>
            </div>

            <div className="w-full md:w-9/12 mx-2 ">
              <div className="bg-white p-3 shadow-sm rounded-sm">
                <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
                  <span clas="text-green-500">
                    <svg
                      className="h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                      />
                    </svg>
                  </span>
                  <span className="tracking-wide">Detalles</span>
                </div>

                <div className="text-gray-700">
                  <div className="grid md:grid-cols-2 text-sm">
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Nombres</div>
                      <div className="px-4 py-2">{persona?.nombre}</div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Apellidos</div>
                      <div className="px-4 py-2">
                        {persona?.apellidopaterno + " " + persona?.apellidomaterno}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Tipo documento</div>
                      <div className="px-4 py-2">{persona?.tipodocumentoregistro}</div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Nro documento</div>
                      <div className="px-4 py-2">{persona?.documentoregistro}</div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Sexo</div>
                      <div className="px-4 py-2">Masculino</div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Celular</div>
                      <div className="px-4 py-2">{persona?.celular}</div>
                    </div>

                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Correo</div>
                      <div className="px-4 py-2">
                        <a className="text-blue-800" href={"mailto:" + persona?.email}>
                          {persona?.email}
                        </a>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">F. nacimiento</div>
                      <div className="px-4 py-2">
                        {moment(persona?.fechanacimiento).format("LL")}
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Apoderado</div>
                      <div className="px-4 py-2">{persona?.apoderado}</div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Apoderado celular</div>
                      <div className="px-4 py-2">{persona?.apoderadocelular}</div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Apoderado correo</div>
                      <div className="px-4 py-2">{persona?.apoderadoemail}</div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Padre celular</div>
                      <div className="px-4 py-2">{persona?.padrecelular}</div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="px-4 py-2 font-semibold">Madre celular</div>
                      <div className="px-4 py-2">{persona?.madrecelular}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-4"></div>

              <div className="bg-white p-3 shadow-sm rounded-sm">
                <div className="grid grid-cols-2">
                  <div>
                    <div className="flex items-center space-x-2 font-semibold text-gray-900 leading-8 mb-3">
                      <span clas="text-green-500">
                        <svg
                          className="h-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                      </span>
                      <span className="tracking-wide">Postulaciones</span>
                    </div>
                    <ul className="list-inside space-y-2">
                      {postulaciones.map((item) => (
                        <li key={item.idperiodo}>
                          <div className="text-teal-600">{item.denominacion}</div>
                          <div className="text-gray-500 text-xs">
                            {item.estado} - {item.nivelgrado}
                          </div>
                        </li>
                      ))}

                      {/* <li>
                        <div className="text-teal-600">2023-II FASE II</div>
                        <div className="text-gray-500 text-xs">Nota: 345 / 600 (No ingresó)</div>
                      </li>
                      <li>
                        <div className="text-teal-600">2023-II EXTRAORDINARIO</div>
                        <div className="text-gray-500 text-xs">Nota: 450 / 600 (Ingresó)</div>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonaDetail;
