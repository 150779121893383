import { List, Card, Modal } from "antd";
import { useEffect, useState } from "react";
import { getOne } from "../../api/user";
import { useAuth } from "../../utils/useAuth";
import ChangePassword from "./ChangePassword";

const MiCuenta = () => {
  const { usuario: user } = useAuth();
  const [usuario, setUsuario] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  useEffect(() => {
    getOne(user.id)
      .then((res) => {
        setUsuario(res.payload);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showChangePassword = () => {
    setIsModalVisible(true);
    setModalTitle("Cambiar contraseña");
    setModalContent(<ChangePassword usuario={usuario} closeModal={closeModal} />);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="w-full py-6 lg:p-6">
      <Card className="w-full md:w-1/2 mx-auto max-w-xl">
        <h1 className="text-2xl font-bold mb-3">Vista general de la cuenta</h1>

        <h2 className="text-xl font-bold my-3">Perfil</h2>

        <List>
          <List.Item>
            <p className="text-gray-500 text-base">Nombre de usuario</p>
            <p className="font-semibold text-base">{usuario?.usuario}</p>
          </List.Item>

          <List.Item>
            <p className="text-gray-500 text-base">Nombres</p>
            <p className="font-semibold text-base">{usuario?.nombre}</p>
          </List.Item>
          <List.Item>
            <p className="text-gray-500 text-base">Apellidos</p>
            <p className="font-semibold text-base">{usuario?.apellido}</p>
          </List.Item>
          <List.Item>
            <p className="text-gray-500 text-base">Celular</p>
            <p className="font-semibold text-base">{usuario?.celular}</p>
          </List.Item>
          <List.Item>
            <p className="text-gray-500 text-base">DNI</p>
            <p className="font-semibold text-base">{usuario?.documentoregistro}</p>
          </List.Item>
          <List.Item>
            <p className="text-gray-500 text-base">Correo electrónico</p>
            <p className="font-semibold text-base">{usuario?.email}</p>
          </List.Item>
          <List.Item>
            <p className="text-gray-500 text-base">Rol</p>
            <p className="font-semibold text-base">{usuario?.rol}</p>
          </List.Item>
        </List>

        <button className="mt-10 rounded-full border px-6 py-2" onClick={showChangePassword}>
          Cambiar contraseña
        </button>
      </Card>

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        maskClosable={false}
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default MiCuenta;
