import { Tag, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import moment from "moment";
import * as SHARED from "../../utils/shared";

/**
 * createColumns
 * Complemento para mostrar las tablas tanto como en antd y modo responsive
 * @param {Function} showEditModal
 * @param {Function} showDeleteModal
 * @param {Number} page
 */
export function createColumns(
  onChangeState = null,
  handleOpenInNewTab = null,
  handleGoDetail = null
) {
  return [
    {
      title: "ID",
      key: "idpostulante",
      dataIndex: "idpostulante"
    },
    {
      title: "Asignación",
      key: "asignado",
      dataIndex: "asignado"
    },
    {
      title: "Estado",
      dataIndex: "idestado",
      key: "idestado",
      render: (state) => {
        return (
          <Tag color={SHARED.COLOR_ESTADO[state]} key={state}>
            {SHARED.LABEL_ESTADO[state] || "No inscrito"}
          </Tag>
        );
      }
    },
    {
      title: "DNI",
      dataIndex: "documentoregistro",
      key: "documentoregistro",
      sorter: (a, b) => a.documentoregistro.localeCompare(b.documentoregistro),
      render: (_codigo, record) => (
        <Button type="link" onClick={() => handleGoDetail(record)}>
          {record.documentoregistro}
        </Button>
      ),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Apellidos",
      dataIndex: "apellido",
      key: "apellido",
      sorter: (a, b) => a.apellido.localeCompare(b.apellido),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Nombres",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      sortDirections: ["descend", "ascend"]
    },
    // {
    //   title: "Carrera",
    //   dataIndex: "carrera",
    //   key: "carrera"
    // },
    {
      title: "Hace",
      dataIndex: "fechaactualizo",
      key: "fechaactualizo",
      render: (fechaactualizo) => {
        return <p>{moment(fechaactualizo).fromNow(true)}</p>;
      }
    },
    {
      title: "Acciones",
      key: "action",
      render: (record) => (
        <Button size="small" icon={<EyeOutlined />} onClick={() => handleOpenInNewTab(record)} />
      )
    }
  ];
}
