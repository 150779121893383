import { Table } from "antd";

function TableAntd(props) {
  const {
    dataSource = [],
    columns = [],
    setPage,
    pageSize = 20,
    total = 0,
    isLoading = false,
    setPageSize
  } = props;

  const dataSourceWithKeys = dataSource.map((item, index) => {
    return { ...item, key: index };
  });

  const handleTableChange = (pagination) => {
    setPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <div className="overflow-x-auto w-auto">
      <Table
        rowClassName={(record, index) => (index % 2 === 0 ? "bg-white" : "bg-gray-100")}
        columns={columns}
        loading={isLoading}
        size="small"
        dataSource={dataSourceWithKeys}
        onChange={handleTableChange}
        pagination={{
          total,
          pageSize,
          onChange(current) {
            setPage(current);
          }
        }}
      />
    </div>
  );
}

export default TableAntd;
