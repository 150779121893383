import * as HTTP_REQUEST from "./api";
import { handleResponse } from "../utils/functions";

const PATH_MODEL = "/api/ingresantes";

export const getIngresantes = async (body) => {
  const response = await HTTP_REQUEST.post(`${PATH_MODEL}/`, body);
  return handleResponse(response);
};

export const executeQuery = async (body) => {
  const response = await HTTP_REQUEST.post(`${PATH_MODEL}/execute`, body);
  return handleResponse(response);
};

export const marcarConstanciaEntregada = async (body) => {
  const response = await HTTP_REQUEST.post(`${PATH_MODEL}/marcar-constancia-entregada`, body);
  return handleResponse(response);
};

export const marcarConstanciaNoEntregada = async (body) => {
  const response = await HTTP_REQUEST.post(`${PATH_MODEL}/marcar-constancia-no-entregada`, body);
  return handleResponse(response);
};

export const executeUpdate = async ({ nombretabla, nombreid, valorid }, body) => {
  const response = await HTTP_REQUEST.put(
    `${PATH_MODEL}/${nombretabla}/${nombreid}/${valorid}`,
    body
  );
  return handleResponse(response);
};

// Obtiene el excel file
export const reportQuery = async (data) => {
  const response = await HTTP_REQUEST.postFile(`${PATH_MODEL}/reporte`, data);
  return response; // return a blob
};
