import { Fragment } from "react";
import { TitleHeader, CardReport } from "../../components";
import lista_usuario from "../../assets/lista_usuario.png";
import { reportQuery } from "../../api/sistema";
import { reportQuery as reportIngresante } from "../../api/ingresante";
import { DATE_LIKE_NAME } from "../../utils/functions";
import { TITLE_SISTEMA_ABREV, OBJ_ROLES } from "../../utils/shared";
import { getPeriodo } from "../../utils/functions";
import { useAuth } from "../../utils/useAuth";

const idPeriodo = getPeriodo()?.idperiodo || 0;

function Reportes(props) {
  const { rol } = useAuth();

  // ------------- Descarga supervisores -------------
  const downloadExcelResumen = async () => {
    const blob = await reportQuery({
      query: "get_reporte_registrados_por_periodo",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-RESUMEN-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadExcelInscritosDetallado = async () => {
    const blob = await reportQuery({
      query: "get_reporte_segunda_especialidad_registrados_por_periodo",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-DETALLADO-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadInscritosPorCanal = async () => {
    const blob = await reportQuery({
      query: "get_reporte_cantidad_inscritos_por_canal",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-POR-CANAL-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadInscritosPorResidencia = async () => {
    const blob = await reportQuery({
      query: "get_reporte_inscritos_por_residencia",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-POR-RESIDENCIA-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadReporteMedioPago = async () => {
    const blob = await reportQuery({
      query: "get_reporte_por_medios_pago",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-MEDIO-PAGO-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadReportePorSexo = async () => {
    const blob = await reportQuery({
      query: "get_reporte_de_cantidad_postulantes_por_sexo",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-REPORTE-POR-SEXO-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadIngresantes = async () => {
    const blob = await reportIngresante({
      query: "get_reporte_de_total_ingresantes"
      // variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${TITLE_SISTEMA_ABREV}-REPORTE-INGRESANTES-${DATE_LIKE_NAME}.xlsx`
    );
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadIngresantesFirmadosObs = async () => {
    const blob = await reportIngresante({
      query: "get_reporte_ingresantes_firmados_datos_faltantes"
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${TITLE_SISTEMA_ABREV}-REPORTE-INGRESANTES-FIRMADOS-OBSERVADOS-${DATE_LIKE_NAME}.xlsx`
    );
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadReporteFotografiaRecortada = async () => {
    const blob = await reportQuery({
      query: "get_reporte_recorte_fotos",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-REPORTE-FOTOS-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <Fragment>
      <TitleHeader title="Reportes" />
      {(rol === OBJ_ROLES.DIRECTIVO || rol === OBJ_ROLES.ADMINISTRADOR) && (
        <div className="flex flex-col gap-2 md:flex-row flex-wrap">
          <CardReport
            onClick={downloadExcelResumen}
            desc="Descargar resumen inscritos"
            imgSrc={lista_usuario}
            imgAlt="Reporte de inscritos"
          />

          <CardReport
            onClick={downloadInscritosPorCanal}
            desc="Cantidad por canal"
            imgSrc={lista_usuario}
            imgAlt="Cantidad por canal"
          />

          <CardReport
            onClick={downloadInscritosPorResidencia}
            desc="Reporte por lugar de residencia"
            imgSrc={lista_usuario}
            imgAlt="Reporte por lugar de residencia"
          />

          <CardReport
            onClick={downloadReportePorSexo}
            desc="Reporte postulantes por sexo"
            imgSrc={lista_usuario}
            imgAlt="Reporte postulantes por sexo"
          />

          <CardReport
            onClick={downloadIngresantes}
            desc="Reporte de ingresantes"
            imgSrc={lista_usuario}
            imgAlt="Reporte de ingresantes"
          />

          <CardReport
            onClick={downloadIngresantesFirmadosObs}
            desc="Reporte de ingresantes firmados observados"
            imgSrc={lista_usuario}
            imgAlt="Reporte de ingresantes firmados observados"
          />

          <CardReport
            onClick={downloadReporteFotografiaRecortada}
            desc="Reporte de recorte de fotografía"
            imgSrc={lista_usuario}
            imgAlt="Reporte de recorte de fotografía"
          />
        </div>
      )}

      {/* Especialista de sistemas */}
      {rol === OBJ_ROLES.ESPECIALISTA_SISTEMAS && (
        <div className="flex flex-col gap-2 md:flex-row flex-wrap">
          <CardReport
            onClick={downloadExcelResumen}
            desc="Descargar resumen inscritos"
            imgSrc={lista_usuario}
            imgAlt="Reporte de inscritos"
          />

          <CardReport
            onClick={downloadReporteMedioPago}
            desc="Reporte por medios de pago"
            imgSrc={lista_usuario}
            imgAlt="Reporte de medios de pago inscritos"
          />

          <CardReport
            onClick={downloadInscritosPorCanal}
            desc="Cantidad por canal"
            imgSrc={lista_usuario}
            imgAlt="Cantidad por canal"
          />

          <CardReport
            onClick={downloadInscritosPorResidencia}
            desc="Reporte por lugar de residencia"
            imgSrc={lista_usuario}
            imgAlt="Reporte por lugar de residencia"
          />

          <CardReport
            onClick={downloadExcelInscritosDetallado}
            desc="Reporte postulantes detallado"
            imgSrc={lista_usuario}
            imgAlt="Reporte de inscritos detallado"
          />

          <CardReport
            onClick={downloadReportePorSexo}
            desc="Reporte postulantes por sexo"
            imgSrc={lista_usuario}
            imgAlt="Reporte postulantes por sexo"
          />
        </div>
      )}

      {/* Segunda especialidad */}
      <div className="flex flex-col gap-2 md:flex-row">
        {rol === OBJ_ROLES.SEGUNDA_ESPECIALIDAD && (
          <CardReport
            onClick={downloadExcelInscritosDetallado}
            desc="Descargar inscritos"
            imgSrc={lista_usuario}
            imgAlt="Reporte de inscritos detallado"
          />
        )}
      </div>

      {rol === OBJ_ROLES.SUPERADMIN && (
        <div className="flex flex-col gap-2 md:flex-row flex-wrap">
          <CardReport
            onClick={downloadExcelResumen}
            desc="Descargar resumen inscritos"
            imgSrc={lista_usuario}
            imgAlt="Reporte de inscritos"
          />

          <CardReport
            onClick={downloadReporteMedioPago}
            desc="Reporte por medios de pago"
            imgSrc={lista_usuario}
            imgAlt="Reporte de medios de pago inscritos"
          />

          <CardReport
            onClick={downloadInscritosPorCanal}
            desc="Cantidad por canal"
            imgSrc={lista_usuario}
            imgAlt="Cantidad por canal"
          />

          <CardReport
            onClick={downloadInscritosPorResidencia}
            desc="Reporte por lugar de residencia"
            imgSrc={lista_usuario}
            imgAlt="Reporte por lugar de residencia"
          />

          <CardReport
            onClick={downloadExcelInscritosDetallado}
            desc="Reporte postulantes detallado"
            imgSrc={lista_usuario}
            imgAlt="Reporte de inscritos detallado"
          />

          <CardReport
            onClick={downloadReportePorSexo}
            desc="Reporte postulantes por sexo"
            imgSrc={lista_usuario}
            imgAlt="Reporte postulantes por sexo"
          />

          <CardReport
            onClick={downloadIngresantes}
            desc="Reporte de ingresantes"
            imgSrc={lista_usuario}
            imgAlt="Reporte de ingresantes"
          />

          <CardReport
            onClick={downloadIngresantesFirmadosObs}
            desc="Reporte de ingresantes firmados observados"
            imgSrc={lista_usuario}
            imgAlt="Reporte de ingresantes firmados observados"
          />

          <CardReport
            onClick={downloadReporteFotografiaRecortada}
            desc="Reporte de recorte de fotografía"
            imgSrc={lista_usuario}
            imgAlt="Reporte de recorte de fotografía"
          />
        </div>
      )}

      {/* Reporte por pagos */}

      {/* Cantidad por canales */}
    </Fragment>
  );
}

export default Reportes;
