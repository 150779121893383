import { useHistory } from "react-router-dom";
import { Form, Input, message, Button } from "antd";
import { changePassword } from "../../api/user";

function ChangePassword({ usuario, closeModal }) {
  const history = useHistory();

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  // ------ Actualiza usuario ------
  const sendDataToServer = async (data) => {
    try {
      if (data.newpassword !== data.repeatnewpassword) {
        throw new Error("La nueva contraseña y repetir la nueva contraseña no coinciden");
      }

      const response = await changePassword(data, usuario.id);
      message.success(response.message);

      history.replace("/app/micuenta");
      closeModal();
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Contraseña actual"
        name="password"
        className="mt-4"
        rules={[{ required: true, message: "El campo contraseña actual es necesario" }]}
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item
        label="Nueva contraseña"
        name="newpassword"
        className="mt-4"
        rules={[
          {
            required: true,
            message: "El campo contraseña actual es necesario"
          },
          {
            min: 6,
            message: "La contraseña debe tener como mínimo 6 caracteres"
          }
        ]}
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item
        label="Repetir nueva contraseña"
        name="repeatnewpassword"
        className="mt-4"
        rules={[
          {
            required: true,
            message: "El campo contraseña actual es necesario"
          },
          {
            min: 6,
            message: "La nueva contraseña debe tener como mínimo 6 caracteres"
          }
        ]}
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item className="mt-8">
        <Button type="primary" block htmlType="submit">
          Establecer nueva contraseña
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ChangePassword;
