import { Button } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { OBJ_ROLES } from "../../utils/shared";

/**
 * createColumns
 * Complemento para mostrar las tablas tanto como en antd y modo responsive
 * @param {Function} showEditModal

 * @param {Number} page
 * @param {Number} pageSize
 * @param {Function} setConstanciaNoEntregada
 * @param {Object} usuario
 * @param {Array} carrerasFilter
 */
export function createColumns(
  page = 1,
  pageSize = 10,
  setConstanciaNoEntregada = null,
  usuario = null,
  carrerasFilter = []
) {
  const columnaQuitarConstancia =
    usuario.rol === OBJ_ROLES.SUPERADMIN || usuario.rol === OBJ_ROLES.ADMINISTRADOR
      ? {
          title: "Acciones",
          key: "action",
          render: (record) => (
            <>
              {record.constanciaentregada ? (
                <Button
                  size="small"
                  icon={<MinusCircleOutlined />}
                  onClick={() => setConstanciaNoEntregada(record)}
                />
              ) : (
                <></>
              )}
            </>
          )
        }
      : {};

  return [
    {
      title: "N.º",
      key: "index",
      render: (value, item, index) => (page - 1) * pageSize + index + 1
    },
    {
      title: "DNI",
      dataIndex: "documentoregistro",
      key: "documentoregistro",
      sorter: (a, b) => a.documentoregistro.localeCompare(b.documentoregistro),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Apellido",
      dataIndex: "apellido",
      key: "apellido",
      sorter: (a, b) => a.apellido.localeCompare(b.apellido),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Examen",
      dataIndex: "idperiodo",
      key: "idperiodo",
      sortDirections: ["descend", "ascend"],
      filters: [
        {
          value: "101",
          text: "2024 - CEPU CICLO III"
        },
        {
          value: "97",
          text: "2024 - FASE II"
        },
        {
          value: "98",
          text: "2024 - EXTR. TRASLADOS INTERNOS"
        },
        {
          value: "95",
          text: "2024 - FASE I"
        },
        {
          value: "96",
          text: "2024 - EXTRAORDINARIO"
        },
        {
          value: "99",
          text: "2024 - CEPU CICLO I"
        },
        {
          value: "100",
          text: "2024 - CEPU CICLO II"
        }
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.idperiodo) {
          return record.idperiodo.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => {
        return a.idperiodo - b.idperiodo;
      },
      render: (value, record) => <p>{record.periodo}</p>
    },
    {
      title: "Canal",
      dataIndex: "idcanal",
      key: "idcanal",
      sortDirections: ["descend", "ascend"],
      filters: [
        {
          text: "CANAL 1",
          value: parseInt("1")
        },
        {
          text: "CANAL 2",
          value: parseInt("2")
        },
        {
          text: "CANAL 3",
          value: parseInt("3")
        },
        {
          text: "CANAL 4",
          value: parseInt("4")
        }
      ],
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.idcanal) {
          return record.idcanal.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => {
        return a.idcanal - b.idcanal;
      },
      render: (value, record) => <p>{record.canal}</p>
    },
    {
      title: "Carrera",
      dataIndex: "programaestudio",
      key: "programaestudio",
      filters: carrerasFilter.map((carrera) => ({ text: carrera, value: carrera })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.programaestudio) {
          return record.programaestudio.toString() === value.toString();
        }
        return false;
      },

      sorter: (a, b) => a.programaestudio.localeCompare(b.programaestudio),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Entregado",
      dataIndex: "constanciaentregada",
      key: "constanciaentregada",
      render: (constanciaentregada) => {
        if (!constanciaentregada) return <p></p>;
        return <p className="font-bold">SÍ</p>;
      },
      filters: [
        { text: "SÍ", value: 1 },
        { text: "NO", value: 0 }
      ],
      onFilter: (value, record) => record.constanciaentregada === value,
      sortDirections: ["descend", "ascend"]
    },
    // agregar carrera
    {
      title: "Día",
      dataIndex: "fechaentregaconstancia",
      key: "fechaentregaconstancia",
      render: (fechaentregaconstancia) => {
        if (!fechaentregaconstancia) return <p></p>;
        return <p>{moment(fechaentregaconstancia).format("L")}</p>;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Hora",
      dataIndex: "fechaentregaconstancia",
      key: "fechaentregaconstancia",
      render: (fechaentregaconstancia) => {
        if (!fechaentregaconstancia) return <p></p>;
        return <p>{moment(fechaentregaconstancia).format("hh:mm A")}</p>;
      },
      sortDirections: ["descend", "ascend"]
    },
    columnaQuitarConstancia
  ];
}
