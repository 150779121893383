import { useState, useEffect } from "react";
import { Form, Input, message, Button, Select, Tooltip } from "antd";
import * as AppApi from "../../api/ingresante";

const { Option } = Select;

const DatosPago = ({ record, isDisabled }) => {
  const [catalogo, setCatalogo] = useState({
    formapago: []
  });

  useEffect(() => {
    fetchCatalogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCatalogo = async () => {
    const res1 = await AppApi.executeQuery({ query: "get_forma_pago" });

    setCatalogo({
      formapago: res1.payload
    });
  };

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  // ------- Conexion con api -------
  const sendDataToServer = async (data) => {
    const params = {
      nombretabla: "reg_ingresante",
      nombreid: "idingresante",
      valorid: record?.idingresante
    };

    AppApi.executeUpdate(params, data)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
      <h1 className="text-azul-700 font-semibold text-base mb-2">PAGO CARPETA DE INGRESANTE</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <Form.Item
          label="Forma pago"
          name="idformapagoconstancia"
          initialValue={record?.idformapagoconstancia}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.formapago.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Importe en soles (S/)"
          name="importeconstancia"
          initialValue={record?.importeconstancia}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Fecha comprobante"
          name="fechacomprobanteconstancia"
          initialValue={record?.fechacomprobanteconstancia}
        >
          <Input type="date" disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label={
            <Tooltip title="Los pagos BCP tienen 08 dígitos (llenar con ceros a la izquiera para completar a 08 dígitos). Los pagos en Banco de la Nación y Págalo.pe 07 dígitos. Los pagos de Scotiabank 15 dígitos y sin puntos">
              Número de comprobante {" (ojo)"}
            </Tooltip>
          }
          name="numerocomprobanteconstancia"
          initialValue={record?.numerocomprobanteconstancia}
        >
          <Input disabled={isDisabled} />
        </Form.Item>
      </div>

      <hr className="mt-4" />
      <h1 className="text-azul-700 font-semibold text-base my-5">PAGO INGRESO DIRECTO CEPU</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <Form.Item
          label="Forma pago"
          name="idformapagoingresocepu"
          initialValue={record?.idformapagoingresocepu}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.formapago.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Importe en soles (S/)"
          name="importeingresocepu"
          initialValue={record?.importeingresocepu}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Fecha comprobante"
          name="fechacomprobanteingresocepu"
          initialValue={record?.fechacomprobanteingresocepu}
        >
          <Input type="date" disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label={
            <Tooltip title="Los pagos BCP tienen 08 dígitos (llenar con ceros a la izquiera para completar a 08 dígitos). Los pagos en Banco de la Nación y Págalo.pe 07 dígitos. Los pagos de Scotiabank 15 dígitos y sin puntos">
              Número de comprobante {" (ojo)"}
            </Tooltip>
          }
          name="numerocomprobanteingresocepu"
          initialValue={record?.numerocomprobanteingresocepu}
        >
          <Input disabled={isDisabled} />
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DatosPago;
