import { COLOR_ESTADO_TAILWIND, LABEL_ESTADO } from "../../utils/shared";
import FOTO_DEFAULT from "../../assets/user_icon.png";

/**
 * UserCard
 * @param {String} title
 * @param {String} subTitle
 * @param {String} state
 * @returns
 */

function UserCard(props) {
  const { title = "", subTitle = "", state = "", image = "", codigo = "" } = props;

  const bg_color = COLOR_ESTADO_TAILWIND[state] || "white";
  const label_estado = LABEL_ESTADO[state] || "No definido";

  return (
    <div className="flex flex-col items-center bg-white sm:max-w-full max-w-md rounded overflow-hidden shadow-lg">
      <img
        className="rounded"
        src={image || FOTO_DEFAULT}
        alt={`Foto ${title}`}
        width={64}
        height={70}
      />
      <div className="flex flex-col items-center text-center pt-1 pb-6">
        <p className="py-2 text-base font-semibold text-azul-800">{title}</p>
        <p className="text-sm text-gray-600">{subTitle}</p>
        <p className="text-sm text-gray-600">{codigo}</p>

        {state && (
          <div className="mt-5">
            <p className={`border rounded-full ${bg_color} py-2 px-8 text-xs font-semibold`}>
              {label_estado}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default UserCard;
