import { Fragment, useState, useEffect } from "react";
import { notification, Button, Select } from "antd";
import { TitleHeader, Searcher, TableAntd } from "../../components";
import { ReloadOutlined } from "@ant-design/icons";
import { executeQuery } from "../../api/ingresante";
import { createColumns } from "./formatTable";
import { SELECT_ESTADOS_INGRESANTE, INSTITUCION } from "../../utils/shared";
import {
  openInNewTab,
  getPeriodo,
  filterRepeatedAndReturnUniqueValues
} from "../../utils/functions";
import { useHistory } from "react-router-dom";
import * as AppApi from "../../api/sistema";

const { Option } = Select;
const PATH_MODELO = "/app/ingresante";
// const idPeriodo = getPeriodo()?.idperiodo || 0;
const idInstitucion = getPeriodo()?.idinstitucion || 0;

function Ingresante() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const [dataToTable, setDataToTable] = useState([]);
  const [filterTable, setFilterTable] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onSelect, setOnSelect] = useState("");
  const [onSelectAsignado, setOnSelectAsignado] = useState("");
  const [revisores, setRevisores] = useState([]);

  const fetchDataToTable = async () => {
    setIsLoading(true);

    executeQuery({
      query: "get_all_ingresantes",
      variables: [
        { name: "ASIGNADO", value: onSelectAsignado || "%%", type: "string" },
        { name: "IDESTADO", value: onSelect || "%%", type: "string" }
      ]
    })
      .then((data) => {
        setDataToTable(data.payload);
        setTotalData(data.payload.length);
      })
      .catch((err) => {
        console.log(err);
        notification.error({
          message: "Problemas con la solicitud",
          description: err.message
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchDataToPage = async () => {
    AppApi.executeQuery({
      query: "get_personales_revisores"
    })
      .then((res) => {
        setRevisores(res.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const contarPorEstado = (array, idestado) => {
    return array.filter((objeto) => objeto.idestado === idestado).length;
  };

  const ESTADO = {
    incompleto: contarPorEstado(dataToTable, 1),
    pendiente: contarPorEstado(dataToTable, 2),
    observado: contarPorEstado(dataToTable, 3),
    firmado: contarPorEstado(dataToTable, 4)
  };

  useEffect(() => {
    fetchDataToPage();
  }, []);

  useEffect(() => {
    fetchDataToTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pageSize, page, onSelect, onSelectAsignado]);

  const handleOpenInNewTab = async (record) => {
    openInNewTab(`${PATH_MODELO}/${record.idingresante}`);
  };

  const handleGoDetail = (record) => {
    history.push(`${PATH_MODELO}/${record.idingresante}`);
  };

  const carrerasFilter = filterRepeatedAndReturnUniqueValues(dataToTable, "programaestudio");
  const modalidadFilter = filterRepeatedAndReturnUniqueValues(dataToTable, "modalidadingreso");

  const columnsToTable = createColumns(
    page,
    pageSize,
    handleOpenInNewTab,
    handleGoDetail,
    carrerasFilter,
    modalidadFilter
  );

  const search = (value) => {
    const tempFilterTable = dataToTable.filter((o) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.toLowerCase()))
    );

    setFilterTable(tempFilterTable);
  };

  const NumberStatus = ({ quantity, label, color }) => (
    <div className="text-base font-semibold mr-4" style={{ color }}>
      {quantity} {label}
    </div>
  );

  return (
    <Fragment>
      <TitleHeader
        title={
          <span className="flex gap-2 items-center">
            <h1 className="font-bold text-lg text-blue-500">({totalData}) Ingresantes</h1>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => setReload(!reload)}
            ></Button>
          </span>
        }
      />

      <div className="lg:flex justify-between">
        <div className="lg:flex w-full  lg:w-3/4 justify-start">
          <NumberStatus quantity={ESTADO.incompleto} label="Incompletos" color="gray" />
          <NumberStatus quantity={ESTADO.pendiente} label="Pendientes" color="skyblue" />
          <NumberStatus quantity={ESTADO.observado} label="Observados" color="orange" />
          <NumberStatus quantity={ESTADO.firmado} label="Firmados" color="green" />
        </div>

        <div className="w-full lg:w-1/4 flex justify-center">
          <Select onSelect={(value) => setOnSelect(value)} defaultValue="" className="w-1/3">
            {SELECT_ESTADOS_INGRESANTE.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>

          {idInstitucion === INSTITUCION.DIAD && (
            <Select
              onSelect={(value) => setOnSelectAsignado(value)}
              defaultValue=""
              className="w-1/3"
            >
              <Option value="">TODOS</Option>
              {revisores.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}

          <Searcher onSearch={search} placeholder="Buscar" className="w-2/3" />
        </div>
      </div>

      <TableAntd
        columns={columnsToTable}
        dataSource={filterTable == null ? dataToTable : filterTable}
        isLoading={isLoading}
        setPage={setPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
      />
    </Fragment>
  );
}

export default Ingresante;
