import { Switch, Route, Redirect } from "react-router-dom";
import LogoDiad from "../../assets/logodiadunjbg.png";
import { useAuth } from "../../utils/useAuth";
import * as SHARED from "../../utils/shared";
import { Link } from "react-router-dom";

function LayoutInicio({ routes }) {
  const { isAdmin } = useAuth();

  if (isAdmin) return <Redirect to="/app" />;
  return (
    <div className="flex flex-col w-full min-h-screen items-center justify-between py-8 px-6">
      <header>
        <Link to="/">
          <img src={LogoDiad} alt="Logo DIAD-UNJBG" />
        </Link>
      </header>
      <main className="mb-auto w-full mx-auto">
        <LoadRoutes routes={routes} />
      </main>
      <footer>
        <p className="text-center font-semibold text-xs text-gray-400">
          {SHARED.TITLE_SISTEMA_SHORT}
        </p>
      </footer>
    </div>
  );
}
export default LayoutInicio;

/**
 * Carga las rutas definidas en el archivo de rutas.
 * @param {Array} routes
 */
function LoadRoutes(props) {
  const { routes, user } = props;
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={(props) => <route.component {...props} user={user} />}
        />
      ))}
    </Switch>
  );
}
