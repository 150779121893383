import { useState, useEffect } from "react";
import { Form, Input, Button, notification, message, Select, Radio, Space } from "antd";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { getListaPeriodosInscripcionActiva, getPeriodo } from "../../utils/functions";
import { ESTADO } from "../../utils/shared";

const { Option } = Select;
const idPeriodo = getPeriodo()?.idperiodo || 0;
const idInstitucion = getPeriodo()?.idinstitucion || 0;
const idModalidadIngreso = getPeriodo()?.idmodalidadingreso || 0;

function PostulanteRegister({ closeModal }) {
  const [documentoregistro, setDocumentoRegistro] = useState("");
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [idEntidad, setIdEntidad] = useState(null);
  const [listaPersonas, setListaPersonas] = useState([]);
  const [catalogo, setCatalogo] = useState({
    periodos_inscripcion_activa: []
  });

  const history = useHistory();

  useEffect(() => {
    fetchCatalogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCatalogo = async () => {
    const periodos_inscripcion_activa = await getListaPeriodosInscripcionActiva();

    setCatalogo({
      periodos_inscripcion_activa
    });
  };

  const onSearch = async () => {
    setIsLoadingSearch(true);

    AppApi.executeQuery({
      query: "get_lista_entidad_by_documentoregistro",
      variables: [{ name: "DOCUMENTOREGISTRO", value: documentoregistro }]
    })
      .then((data) => {
        message.success(data.message);
        setListaPersonas(data.payload);
      })
      .catch((err) => {
        notification.error({
          message: "Problemas con la solicitud",
          description: err.message
        });
      })
      .finally(() => {
        setIsLoadingSearch(false);
      });
  };

  const sendDataToServer = async (data) => {
    try {
      if (!idEntidad || !data.idperiodo) {
        message.error("Debes completar todos los campos");
        return;
      }

      const response = await AppApi.executePost("/api/core/inscribirse-unsafe", {
        identidad: idEntidad,
        idperiodo: data.idperiodo,
        idestado: ESTADO.incompleto,
        aceptoreglamento: true,
        fechaaceptoreglamento: new Date(),
        fechainscripcion: new Date(),
        idinstitucion: idInstitucion,
        idmodalidadingreso: idModalidadIngreso,
        observacioninterno: "Inscrito presencialmente"
      });

      message.success(response.message);
      history.replace("/app/postulante");
      closeModal();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={sendDataToServer}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        <div>
          <Form.Item
            label={
              <>
                <p>Número de documento</p> &nbsp;
                <Button
                  size="small"
                  icon={<SearchOutlined />}
                  loading={isLoadingSearch}
                  onClick={onSearch}
                />
              </>
            }
            name="identidad"
            rules={[{ required: true, message: "El campo es requerido" }]}
          >
            <Input onChange={(e) => setDocumentoRegistro(e.target.value)} />
          </Form.Item>

          <Radio.Group
            className="mt-3"
            onChange={(e) => setIdEntidad(e.target.value)}
            value={idEntidad}
          >
            <Space direction="vertical">
              {listaPersonas.map((persona) => (
                <Radio key={persona.identidad} className="mb-2" value={persona.identidad}>
                  <b>{persona.nombrecompleto}</b> <code>{persona.email}</code>
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </div>

        <Form.Item
          label="Examen"
          name="idperiodo"
          initialValue={idPeriodo}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.periodos_inscripcion_activa.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Registrar
        </Button>
      </Form.Item>
    </Form>
  );
}

export default PostulanteRegister;
