import { Fragment } from "react";
import { Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { deleteOne } from "../../api/user";

/**
 * DeleteUser
 * @param {Object} user
 * @param {Function} closeModal
 */
function DeleteUser({ usuario, closeModal }) {
  const history = useHistory();

  const handleDelete = async (userId) => {
    try {
      const response = await deleteOne(userId);
      message.success(response.message);
      closeModal();
      history.replace("/app/usuario");
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Fragment>
      <p className="my-5">
        ¿Estás seguro de eliminar al usuario
        <span className="text-red-500 my-5">{" " + usuario.nombre + " " + usuario.apellido}</span>?
      </p>
      <Button type="danger" block htmlType="submit" onClick={() => handleDelete(usuario._id)}>
        Eliminar
      </Button>
    </Fragment>
  );
}
export default DeleteUser;
