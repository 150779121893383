import { Form, Input, Button, message, Tooltip } from "antd";
import { formLayout } from "../../utils/shared";
import { useHistory } from "react-router-dom";
import * as AppApi from "../../api/sistema";

function CatalogoAddEdit({ record, closeModal }) {
  const history = useHistory();

  const sendDataToServer = async (data) => {
    try {
      let response = null;

      if (record) {
        const params = {
          nombretabla: "ads_catalogo",
          nombreid: "idcatalogo",
          valorid: record?.idcatalogo
        };

        response = await AppApi.executeUpdate(params, data);
      } else {
        response = await AppApi.executeCreate({ nombretabla: "ads_catalogo" }, data);
      }

      message.success(response.message);
      history.replace("/app/catalogo");
      closeModal();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Form
      {...formLayout.formItemLayout}
      initialValues={{ remember: true }}
      onFinish={sendDataToServer}
    >
      <Form.Item
        label={
          <Tooltip title="El tipo 0000 es una categoría de catálogo y el valor de tipo hijos debe ir en correlativo. Si tipo es un valor diferente de 0000 debes asegurarte que la categoría exista.">
            Tipo {" (ojo)"}
          </Tooltip>
        }
        name="tipo"
        initialValue={record?.tipo || ""}
        rules={[{ required: true, message: "El campo es necesario" }]}
      >
        <Input placeholder="0000" />
      </Form.Item>

      <Form.Item
        label="Denominacion"
        name="denominacion"
        initialValue={record?.denominacion || ""}
        rules={[{ required: true, message: "El campo es necesario" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Correlativo" name="correlativo" initialValue={record?.correlativo || ""}>
        <Input />
      </Form.Item>

      <Form.Item label="Abreviatura" name="abreviatura" initialValue={record?.abreviatura || ""}>
        <Input />
      </Form.Item>

      <Form.Item {...formLayout.tailFormItemLayout}>
        <Button type="primary" block htmlType="submit" className="mt-5">
          Guargar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CatalogoAddEdit;
