import { Fragment, useState, useEffect } from "react";
import { notification, Button, Modal, message } from "antd";
import { TitleHeader, Searcher, TableAntd } from "../../components";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { executeUpdate } from "../../api/sistema";
import { createColumns } from "./formatTable";
import CentroEstudioAddEdit from "./CentroEstudioAddEdit";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";
import { filterRepeatedAndReturnUniqueValues } from "../../utils/functions";

const CentroEstudio = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [dataToTable, setDataToTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterTable, setFilterTable] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [widthModal, setWidthModal] = useState(800);

  const history = useHistory();

  const fetchDataToTable = async () => {
    setIsLoading(true);

    AppApi.executeQuery({
      query: "get_total_lista_centro_estudio"
    })
      .then((res) => {
        setDataToTable(res.payload);
        setTotalData(res.payload.length);
      })
      .catch((err) => {
        notification.error({
          message: "Problemas con la solicitud",
          description: err.message
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataToTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pageSize, page]);

  const showAddModal = async () => {
    setModalTitle("Agregar centro de estudio");
    setWidthModal(800);
    setModalContent(<CentroEstudioAddEdit record={null} closeModal={closeModal} />);
    setIsModalVisible(true);
  };

  const showEditModal = async (record) => {
    setModalTitle("Editar centro de estudio");
    setWidthModal(800);
    setModalContent(<CentroEstudioAddEdit record={record} closeModal={closeModal} />);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const onDelete = async (record) => {
    Modal.confirm({
      title: `¿Estás seguro de eliminar el centro de estudio '${record.denominacion}?'`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, eliminar",
      cancelText: "Cancelar",
      async onOk() {
        try {
          await executeUpdate(
            {
              nombretabla: "ads_centroestudio",
              nombreid: "idcentroestudio",
              valorid: record.idcentroestudio
            },
            { eliminado: true }
          );

          message.success("Registro eliminado satisfactoriamente");
          history.replace("/app/centroestudio");
        } catch (err) {
          message.error(err.message);
        }
      }
    });
  };

  const onChangeState = async (record) => {
    const activateMsg = record.activo ? "desactivar" : "activar";

    Modal.confirm({
      title: `¿Estás seguro de ${activateMsg} el centro de estudio '${record.nombre}?'`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, " + activateMsg,
      cancelText: "Cancelar",
      async onOk() {
        try {
          const response = await executeUpdate(
            {
              nombretabla: "ads_centroestudio",
              nombreid: "idcentroestudio",
              valorid: record.idcentroestudio
            },
            { activo: !record.activo }
          );

          message.success(response.message);
          history.replace("/app/centroestudio");
        } catch (err) {
          message.error(err.message);
        }
      }
    });
  };

  const search = (value) => {
    const tempFilterTable = dataToTable.filter((o) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.toLowerCase()))
    );

    setFilterTable(tempFilterTable);
  };

  const nivelModalidadFilter = filterRepeatedAndReturnUniqueValues(dataToTable, "nivelmodalidad");
  const gestionEducativaFilter = filterRepeatedAndReturnUniqueValues(
    dataToTable,
    "gestioneducativa"
  );
  const departamentoFilter = filterRepeatedAndReturnUniqueValues(dataToTable, "departamento");
  const provinciaFilter = filterRepeatedAndReturnUniqueValues(dataToTable, "provincia");
  const distritoFilter = filterRepeatedAndReturnUniqueValues(dataToTable, "distrito");

  const columnsToTable = createColumns(
    page,
    showEditModal,
    onDelete,
    onChangeState,
    nivelModalidadFilter,
    gestionEducativaFilter,
    departamentoFilter,
    provinciaFilter,
    distritoFilter
  );

  return (
    <Fragment>
      <TitleHeader
        title={
          <span className="flex gap-2 items-center">
            <h1 className="font-bold">Centros de Estudio ({totalData})</h1>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => setReload(!reload)}
            ></Button>
          </span>
        }
        buttonTitle="Nuevo centro de estudio"
        onButtonTitleHeaderClick={showAddModal}
      />

      <Searcher onSearch={search} placeholder="Busca un valor de catálogo" />

      <TableAntd
        columns={columnsToTable}
        dataSource={filterTable == null ? dataToTable : filterTable}
        isLoading={isLoading}
        setPageSize={setPageSize}
        setPage={setPage}
        pageSize={pageSize}
      />

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        maskClosable={false}
        width={widthModal}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
};

export default CentroEstudio;
