import { Fragment } from "react";
import { Button, message } from "antd";
import { useHistory } from "react-router-dom";
import * as AppApi from "../../api/sistema";

/**
 * DeleteUser
 * @param {Object} user
 * @param {Function} closeModal
 */
function DeleteUser({ record, closeModal }) {
  const history = useHistory();

  const handleDelete = async (data) => {
    try {
      await AppApi.executeDelete("/api/core/entidad", data.email);
      const res2 = await AppApi.executeDelete("/api/auth/user", data.email);
      message.success(res2.message);

      closeModal();
      history.replace("/app/persona");
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Fragment>
      <p className="mb-5">
        ¿Estás seguro de eliminar al usuario
        <span className="text-red-500 my-5">
          {" " + record?.nombre + " " + record?.apellidopaterno + " " + record?.apellidomaterno}
        </span>
        ?
      </p>
      <Button type="danger" block htmlType="submit" onClick={() => handleDelete(record)}>
        Eliminar
      </Button>
    </Fragment>
  );
}
export default DeleteUser;
