import { PageHeader, Button } from "antd";

/**
 * TitleHeader
 * @param {String} title
 * @param {String} subTitle
 * @param {String} buttonTitle
 * @param {Function} onButtonTitleHeaderClick
 * @param {Function} onBack
 */
function TitleHeader(props) {
  const {
    title = "Título",
    subTitle = "",
    buttonTitle = "",
    onButtonTitleHeaderClick = null,
    onBack = null,
    extra = null,
  } = props;

  return (
    <PageHeader
      onBack={onBack}
      title={title}
      subTitle={subTitle}
      style={{ padding: "16px 0" }}
      extra={
        <div className="flex">
          {extra}
          {buttonTitle && (
            <Button type="primary" onClick={onButtonTitleHeaderClick}>
              {buttonTitle}
            </Button>
          )}
        </div>
      }
    />
  );
}
export default TitleHeader;
