import { Form, Input, Button, message } from "antd";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";

function ProcesoAddEdit({ record = null, closeModal }) {
  const history = useHistory();

  /**
   * Registra o edita un registro
   */
  const sendDataToServer = async (data) => {
    try {
      let response = null;

      if (record) {
        const params = {
          nombretabla: "pos_proceso",
          nombreid: "idproceso",
          valorid: record?.idproceso
        };

        response = await AppApi.executeUpdate(params, data);
      } else {
        response = await AppApi.executeCreate({ nombretabla: "pos_proceso" }, data);
      }

      message.success(response.message);
      history.replace("/app/proceso");
      closeModal();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={sendDataToServer}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        <Form.Item
          label="Denominación"
          name="denominacion"
          initialValue={record?.denominacion}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Abreviatura"
          name="abreviatura"
          initialValue={record?.abreviatura}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ProcesoAddEdit;
