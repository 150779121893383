import { Fragment, useEffect } from "react";
import { TitleHeader } from "../../components";
import { Button, Form, Input, Switch } from "antd";

function Configuracion() {
  const fetchDataToPage = async () => {};

  useEffect(() => {
    fetchDataToPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  const sendDataToServer = async (data) => {};

  const onChangeState = (value) => {};

  return (
    <Fragment>
      <TitleHeader title="Configuración" />

      <div className="w-full mb-6 md:w-1/4">
        <p className="font-semibold mb-3">Proceso</p>
        <Switch onChange={onChangeState} />
      </div>

      <div className="w-full md:w-1/4">
        <Form initialValues={{ remember: true }} onFinish={onFinish} layout="vertical">
          <Form.Item
            label="Actualizar clave de sistema"
            name="claveSistema"
            rules={[
              {
                required: true,
                message: "La clave de sistema es necesario"
              }
            ]}
          >
            <Input allowClear />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block htmlType="submit">
              Actualizar
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Fragment>
  );
}

export default Configuracion;
