import Lista from "./Lista";

const Constancia = () => {
  return (
    <div className="p-4 mx-auto text-center w-full">
      <div className="">
        <p className="text-base font-bold">ENTREGA DE CONSTANCIAS</p>
        <p className="text-base font-bold text-blue-500 uppercase my-2">
          PROCESO DE ADMISIÓN 2024-I
        </p>
      </div>

      <div className="w-full">
        <Lista />
      </div>
    </div>
  );
};

export default Constancia;
