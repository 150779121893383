/**
 * Api
 * ==========
 * - Conjunto de funciones similares al axios, tiene metodos
 *   post, put delete, se crearon para reducir las líneas de codigo,
 *   en todos los casos tiene el token de acceso
 *
 * - Creado por: Dalthon - Jun 26, 2020 05:57 pm (GMT-5)
 */

import { ACCESS_TOKEN } from "./auth";
export const BASE_PATH = process.env.REACT_APP_BASE_PATH || "";
// "https://postula.admision.dev/"

/**
 * POST - Funcion api generico
 * @param {String} url continuación de la url base
 * @param {Object} body Valores en el body
 */
export const post = (url, body) => {
  const mainUrl = `${BASE_PATH}${url}`;
  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ACCESS_TOKEN
    },
    body: JSON.stringify(body)
  };

  return fetch(mainUrl, params)
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => err);
};

/**
 * PUT - Funcion api generico
 * @param {String} url continuación de la url base
 * @param {Object} body Valores en el body
 * @param {String} id Id del item a modificar
 */
export const put = (url, body = {}, id = "") => {
  const mainUrl = `${BASE_PATH}${url}/${id}`;
  const params = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: ACCESS_TOKEN
    },
    body: JSON.stringify(body)
  };

  return fetch(mainUrl, params)
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => err);
};

/**
 * PUT FILE - Para subir una imagen
 * No le hace Stringify al request
 * @param {String} url continuación de la url base
 * @param {Object} body form data
 * @param {String} id Id del item a modificar
 */
export const putFile = (url, body = {}, id = "") => {
  const mainUrl = `${BASE_PATH}${url}/${id}`;
  const params = {
    method: "PUT",
    headers: {
      Authorization: ACCESS_TOKEN
    },
    body
  };

  return fetch(mainUrl, params)
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => err);
};

/**
 * DELETE - Funcion api generico
 * @param {String} url continuación de la url base
 * @param {String} id Id del valor a elminar
 */
export const delt = (url, id = "") => {
  const mainUrl = `${BASE_PATH}${url}/${id}`;
  const params = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: ACCESS_TOKEN
    }
  };

  return fetch(mainUrl, params)
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => err);
};

/**
 * GET - Funcion api generico
 * @param {String} url continuación de la url base
 * @param {String} id Id del valor
 */
export const get = (url, id = "") => {
  const mainUrl = `${BASE_PATH}${url}/${id}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: ACCESS_TOKEN
    }
  };

  return fetch(mainUrl, params)
    .then((res) => res.json())
    .then((res) => res)
    .catch((err) => err);
};

/**
 * GET - Trae archivo tipo excel
 * https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
 * @param {String} url
 */
export const getFile = (url, id = "") => {
  const mainUrl = `${BASE_PATH}${url}/${id}`;

  const params = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: ACCESS_TOKEN
    }
  };

  return fetch(mainUrl, params)
    .then((res) => res.blob()) // <- diferencia
    .then((res) => res)
    .catch((err) => err);
};

/**
 * GET - Trae archivo tipo excel
 * https://medium.com/yellowcode/download-api-files-with-react-fetch-393e4dae0d9e
 * @param {String} url
 */
export const postFile = (url, body) => {
  const mainUrl = `${BASE_PATH}${url}`;

  const params = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: ACCESS_TOKEN
    },
    body: JSON.stringify(body)
  };

  return fetch(mainUrl, params)
    .then((res) => res.blob()) // <- diferencia
    .then((res) => res)
    .catch((err) => err);
};
