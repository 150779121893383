import { useState, useEffect } from "react";
import { Form, Input, message, Button, Select } from "antd";
import * as AppApi from "../../api/sistema";
import { TIPO_CATALOGO } from "../../utils/shared";

const { Option } = Select;

const DatosProfesionales = ({ record, isDisabled }) => {
  const [departamento, setDepartamento] = useState("");
  const [provincia, setProvincia] = useState("");
  const [distrito, setDistrito] = useState("");
  const [centroestudio, setCentroestudio] = useState("");
  const [catalogo, setCatalogo] = useState({
    tipotrabajo: [],
    sectortrabajo: []
  });
  const [arrayProvincia, setArrayProvincia] = useState([]);
  const [arrayDistrito, setArrayDistrito] = useState([]);
  const [arrayCentroestudio, setArrayCentroestudio] = useState([]);
  const [arrayDepartamentos, setArrayDepartamentos] = useState([]);

  useEffect(() => {
    fetchDepartamentos();
    fetchCentroestudio();
    fetchCatalogo();
    if (record.idubicaciontrabajo) {
      getDefaults(record.idubicaciontrabajo);
    }
    if (record.idcentroestudio) {
      setCentroestudio(record.idcentroestudio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDepartamentos = () => {
    AppApi.executeQuery({
      query: "get_departamentos"
    }).then((data) => {
      setArrayDepartamentos(data.payload);
    });
  };

  const getDefaults = (idubicaciontrabajo) => {
    const iddepartamento = idubicaciontrabajo.slice(0, 2);
    const idprovincia = idubicaciontrabajo.slice(0, 4);
    const iddistrito = idubicaciontrabajo;

    fetchProvincia(iddepartamento);
    fetchDistrito(idprovincia);

    setDepartamento(iddepartamento);
    setProvincia(idprovincia);
    setDistrito(iddistrito);
  };

  const fetchCatalogo = async () => {
    const res1 = await AppApi.executeQuery({
      query: "get_catalogo_by_tipo_like",
      variables: [{ name: "TIPO", value: TIPO_CATALOGO.tipotrabajo }]
    });

    const res2 = await AppApi.executeQuery({
      query: "get_catalogo_by_tipo_like",
      variables: [{ name: "TIPO", value: TIPO_CATALOGO.sectortrabajo }]
    });

    setCatalogo({
      tipotrabajo: res1.payload,
      sectortrabajo: res2.payload
    });
  };

  const fetchProvincia = async (iddepartamento) => {
    AppApi.executeQuery({
      query: "get_provincias_by_departamento",
      variables: [{ name: "IDDEPARTAMENTO", value: iddepartamento }]
    }).then((data) => {
      setArrayProvincia(data.payload);
    });
  };

  const fetchDistrito = async (idprovincia) => {
    AppApi.executeQuery({
      query: "get_distritos_by_provincia",
      variables: [{ name: "IDPROVINCIA", value: idprovincia }]
    }).then((data) => {
      setArrayDistrito(data.payload);
    });
  };

  const fetchCentroestudio = async () => {
    AppApi.executeQuery({
      query: "get_all_centroestudio_educacion_superior"
    }).then((data) => {
      setArrayCentroestudio(data.payload);
    });
  };

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  // ------- Conexion con api -------
  const sendDataToServer = async (data) => {
    const params = {
      nombretabla: "pos_postulante",
      nombreid: "idpostulante",
      valorid: record?.idpostulante
    };

    data.idcentroestudio = centroestudio;
    data.idubicaciontrabajo = distrito;

    AppApi.executeUpdate(params, data)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="form-group md:col-span-2">
          <Form.Item
            label="Universidad donde se tituló"
            rules={[{ required: true, message: "El campo es requerido" }]}
          >
            <Select
              disabled={isDisabled}
              value={centroestudio}
              onSelect={(value) => {
                setCentroestudio(value);
              }}
            >
              <Option value="" disabled>
                Seleccione
              </Option>
              {arrayCentroestudio.map((item) => (
                <Option value={item.id}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Form.Item label="Año de egreso" name="anioegreso" initialValue={record?.anioegreso}>
          <Input type="number" disabled={isDisabled} />
        </Form.Item>

        <div className="form-group md:col-span-2">
          <Form.Item
            label="Carrera profesional"
            name="carreraprofesional"
            initialValue={record?.carreraprofesional}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
        </div>

        <Form.Item
          label="Número de colegiatura"
          name="numerocolegiatura"
          initialValue={record?.numerocolegiatura}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Tipo de centro de trabajo"
          name="idtipotrabajo"
          initialValue={record?.idtipotrabajo}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.tipotrabajo.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Sector de trabajo"
          name="idsectortrabajo"
          initialValue={record?.idsectortrabajo}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.sectortrabajo.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Teléfono o celular de centro de trabajo"
          name="trabajotelefono"
          initialValue={record?.trabajotelefono}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <div className="form-group md:col-span-3">
          <Form.Item
            label="Nombre de centro de trabajo"
            name="trabajolugar"
            initialValue={record?.trabajolugar}
          >
            <Input disabled={isDisabled} />
          </Form.Item>
        </div>

        <Form.Item
          label="Departamento"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            disabled={isDisabled}
            value={departamento}
            onSelect={(value) => {
              setDepartamento(value);
              fetchProvincia(value.slice(0, 2));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDepartamentos.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Provincia" rules={[{ required: true, message: "El campo es requerido" }]}>
          <Select
            disabled={isDisabled}
            value={provincia}
            onSelect={(value) => {
              setProvincia(value);
              fetchDistrito(value.slice(0, 4));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayProvincia.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Distrito" rules={[{ required: true, message: "El campo es requerido" }]}>
          <Select
            disabled={isDisabled}
            value={distrito}
            onSelect={(value) => {
              setDistrito(value);
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDistrito.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DatosProfesionales;
