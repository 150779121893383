import { useState, useEffect } from "react";
import { Form, Input, Button, message, Select } from "antd";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";
import {
  getListaPeriodos,
  getListaCondicionPago,
  getListaConceptos,
  filterOption
} from "../../utils/functions";

const { Option } = Select;

function ModalidadPagoAddEdit({ record = null, closeModal }) {
  const history = useHistory();
  const [catalogo, setCatalogo] = useState({
    periodo: [],
    concepto: [],
    condicionpago: []
  });

  useEffect(() => {
    fetchCatalogo();
  }, []);

  const fetchCatalogo = async () => {
    const periodo = await getListaPeriodos();
    const condicionpago = await getListaCondicionPago();
    const concepto = await getListaConceptos();

    setCatalogo({
      periodo,
      concepto,
      condicionpago
    });
  };

  /**
   * Registra o edita un registro
   */
  const sendDataToServer = async (data) => {
    try {
      let response = null;

      if (record) {
        const params = {
          nombretabla: "pos_modalidadpago",
          nombreid: "idmodalidadpago",
          valorid: record?.idmodalidadpago
        };

        response = await AppApi.executeUpdate(params, data);
      } else {
        response = await AppApi.executeCreate({ nombretabla: "pos_modalidadpago" }, data);
      }

      message.success(response.message);
      history.replace("/app/modalidadpago");
      closeModal();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={sendDataToServer}>
      <div className="grid grid-cols-1 gap-4">
        <Form.Item
          label="Examen"
          name="idperiodo"
          initialValue={record?.idperiodo}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.periodo.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Denominacion"
          name="denominacion"
          initialValue={record?.denominacion || ""}
          rules={[{ required: true, message: "El campo es necesario" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Costo total"
          name="costototal"
          initialValue={record?.costototal || ""}
          rules={[{ required: true, message: "El campo es necesario" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Costo inicial"
          name="costoinicial"
          initialValue={record?.costoinicial || ""}
          rules={[{ required: true, message: "El campo es necesario" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Concepto"
          name="idconcepto"
          initialValue={record?.idconcepto}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select showSearch filterOption={filterOption} options={catalogo.concepto}></Select>
        </Form.Item>

        <Form.Item
          label="Condición pago"
          name="idcondicionpago"
          initialValue={record?.idcondicionpago}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.condicionpago.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}

export default ModalidadPagoAddEdit;
