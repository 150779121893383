import { Fragment, useState, useEffect } from "react";
import { Modal, notification, Button, message, Radio } from "antd";
import { TitleHeader, Searcher, TableAntd } from "../../components";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { optionsWithDisabled } from "../../utils/shared";
import { getAll, updateOne, resetPassword } from "../../api/user";
import { createColumns } from "./formatTable";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import DeleteUser from "./DeleteUser";

function Users() {
  // const { isAdmin } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [filterTable, setFilterTable] = useState(null);
  const [page, setPage] = useState(1);
  const [valueActivo, setValueActivo] = useState(true);

  const [dataToTable, setDataToTable] = useState([]);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const fetchDataToTable = async () => {
    setIsLoading(true);

    getAll()
      .then((res) => {
        setDataToTable(res.payload);

        const tmpFiltered = res.payload.filter((o) => o.activo === true);
        setFilterTable(tmpFiltered);
      })
      .catch((err) => {
        notification.error({
          message: "Problemas con la solicitud",
          description: err.message
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataToTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const showAddModal = () => {
    setIsModalVisible(true);
    setModalTitle("Agregar personal");
    setModalContent(<AddUser closeModal={closeModal} />);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const showEditModal = (usuario) => {
    setIsModalVisible(true);
    setModalTitle("Editar personal");
    setModalContent(<EditUser usuario={usuario} closeModal={closeModal} />);
  };

  const onChangeState = async (record) => {
    const activateMsg = record.activo ? "desactivar" : "activar";

    Modal.confirm({
      title: `¿Estás seguro de ${activateMsg} al usuario '${record.nombre}?'`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, " + activateMsg,
      cancelText: "Cancelar",
      async onOk() {
        try {
          const response = await updateOne({ activo: !record.activo }, record.id);
          message.success(response.message);
          history.replace("/app/usuario");
        } catch (err) {
          message.error(err.message);
        }
      }
    });
  };

  const onResetPassword = async (record) => {
    Modal.confirm({
      title: `¿Estás seguro de resetear la contraseña al usuario '${record.nombre}'?, deberá ingresar con su número de DNI.`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, resetear",
      cancelText: "Cancelar",
      async onOk() {
        try {
          const response = await resetPassword({ password: record.documentoregistro }, record.id);
          message.success(response.message);
          history.replace("/app/usuario");
        } catch (err) {
          message.error(err.message);
        }
      }
    });
  };

  const showDeleteModal = (usuario) => {
    setIsModalVisible(true);
    setModalTitle("Eliminar usuario");
    setModalContent(<DeleteUser usuario={usuario} closeModal={closeModal} />);
  };

  // if (!isAdmin) return <Redirect to="/app" />;

  const columnsToTable = createColumns(
    page,
    showEditModal,
    showDeleteModal,
    onChangeState,
    onResetPassword
  );

  const search = (value) => {
    const tempFilterTable = dataToTable.filter((o) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.toLowerCase()))
    );

    setFilterTable(tempFilterTable);
  };

  const onChangeActivo = ({ target: { value } }) => {
    setValueActivo(value);

    const tmpFiltered = dataToTable.filter((o) => o.activo === Boolean(value));
    setFilterTable(tmpFiltered);
  };

  return (
    <Fragment>
      <TitleHeader
        title={
          <span className="flex gap-2 items-center">
            <h1 className="font-bold">Personal ({dataToTable.length})</h1>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => setReload(!reload)}
            ></Button>
          </span>
        }
        buttonTitle="Nuevo personal"
        onButtonTitleHeaderClick={showAddModal}
      />

      <Fragment>
        <div className="flex w-full justify-between">
          <div className="w-full md:w-2/3 lg:w-4/5">
            <Searcher onSearch={search} placeholder="Busca algún personal" />
          </div>
          <div className="w-full md:w-1/3 lg:w-1/5">
            <Radio.Group
              options={optionsWithDisabled}
              onChange={onChangeActivo}
              value={valueActivo}
              optionType="button"
              buttonStyle="solid"
            />
          </div>
        </div>
      </Fragment>

      <TableAntd
        columns={columnsToTable}
        dataSource={filterTable == null ? dataToTable : filterTable}
        setPage={setPage}
        isLoading={isLoading}
      />

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        maskClosable={false}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
}

export default Users;
