import { useState, useEffect } from "react";
import { Form, Input, DatePicker, Button, Select, message, Switch } from "antd";
import { formLayout, TIPO_CATALOGO } from "../../utils/shared";
import { useHistory } from "react-router-dom";
import {
  getDataCatalogo,
  getListaInstituciones,
  getListaFacultades,
  getListaProcesos
} from "../../utils/functions";
import moment from "moment";
import { addOne, updateOne } from "../../api/periodo";

const { Option } = Select;

function PeriodoAddEdit({ record, closeModal }) {
  const history = useHistory();
  const [isFechaSegundo, setIsFechaSegundo] = useState(false);

  const [catalogo, setCatalogo] = useState({
    modalidadingreso: [],
    nivelgrado: [],
    instituciones: [],
    facultades: [],
    procesos: []
  });

  useEffect(() => {
    fetchCatalogo();

    if (record?.fechaexamensegundo && record?.fechaexamensegundo !== null) {
      setIsFechaSegundo(true);
    }
  }, []);

  const onFinish = async (values) => {
    try {
      let response = null;

      if (record) {
        response = await updateOne(values, record.idperiodo);
      } else {
        response = await addOne(values);
      }

      message.success(response.message);
      history.replace("/app/periodo");
      closeModal();
    } catch (error) {
      message.error(error.message);
    }
  };

  const fetchCatalogo = async () => {
    const modalidadingreso = await getDataCatalogo(TIPO_CATALOGO.modalidadingreso);
    const nivelgrado = await getDataCatalogo(TIPO_CATALOGO.nivelgrado);
    const instituciones = await getListaInstituciones();
    const facultades = await getListaFacultades();
    const procesos = await getListaProcesos();

    setCatalogo({
      modalidadingreso,
      nivelgrado,
      instituciones,
      facultades,
      procesos
    });
  };

  return (
    <Form {...formLayout.formItemLayout} initialValues={{ remember: true }} onFinish={onFinish}>
      <Form.Item
        label="Denominacion"
        name="denominacion"
        initialValue={record?.denominacion || ""}
        rules={[{ required: true, message: "El campo es necesario" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Referencia" name="referencia" initialValue={record?.referencia || ""}>
        <Input />
      </Form.Item>

      <Form.Item label="Reglamento" name="reglamento" initialValue={record?.reglamento || ""}>
        <Input.TextArea />
      </Form.Item>

      <Form.Item label="Fecha inicio" name="fechainicio" initialValue={moment(record?.fechainicio)}>
        <DatePicker />
      </Form.Item>

      <Form.Item
        label="Fecha fin"
        name="fechafinalizacion"
        initialValue={moment(record?.fechafinalizacion)}
      >
        <DatePicker />
      </Form.Item>

      <Form.Item label="Año" name="anio" initialValue={record?.anio || ""}>
        <Input type="number" />
      </Form.Item>

      <Form.Item label="Ciclo" name="ciclo" initialValue={record?.ciclo || ""}>
        <Input type="number" />
      </Form.Item>

      <Form.Item label="Orden" name="orden" initialValue={record?.orden || ""}>
        <Input type="number" />
      </Form.Item>

      <Form.Item label="Fecha examen" name="fechaexamen" initialValue={moment(record?.fechaexamen)}>
        <DatePicker />
      </Form.Item>

      <Form.Item label="Fecha segundo?">
        <Switch
          size="small"
          checked={isFechaSegundo}
          onClick={() => setIsFechaSegundo(!isFechaSegundo)}
        />
      </Form.Item>

      {isFechaSegundo && (
        <Form.Item
          label="Fecha examen segundo"
          name="fechaexamensegundo"
          initialValue={moment(record?.fechaexamensegundo)}
        >
          <DatePicker />
        </Form.Item>
      )}

      <Form.Item label="Proceso" name="idproceso" initialValue={record?.idproceso || ""}>
        <Select>
          <Option value="" disabled>
            Seleccione
          </Option>
          {catalogo.procesos.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Modalidad ingreso"
        name="idmodalidadingreso"
        initialValue={record?.idmodalidadingreso || ""}
      >
        <Select>
          <Option value="" disabled>
            Seleccione
          </Option>
          {catalogo.modalidadingreso.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Nivel grado" name="idnivelgrado" initialValue={record?.idnivelgrado || ""}>
        <Select>
          <Option value="" disabled>
            Seleccione
          </Option>
          {catalogo.nivelgrado.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Institucion"
        name="idinstitucion"
        initialValue={record?.idinstitucion || ""}
      >
        <Select>
          <Option value="" disabled>
            Seleccione
          </Option>
          {catalogo.instituciones.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Facultad Seg. Esp."
        name="idfacultad"
        initialValue={record?.idfacultad || ""}
      >
        <Select>
          <Option value="" disabled>
            Seleccione
          </Option>
          {catalogo.facultades.map((item, index) => (
            <Option key={index} value={item.id}>
              {item.label}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item {...formLayout.tailFormItemLayout}>
        <Button type="primary" block htmlType="submit" className="mt-5">
          Guargar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}

export default PeriodoAddEdit;
