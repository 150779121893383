import { Button, Switch, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

/**
 * createColumns
 * Complemento para mostrar las tablas tanto como en antd y modo responsive
 * @param {Function} showEditModal
 * @param {Function} onDelete
 * @param {Number} page
 */
export function createColumns(
  page = 1,
  showEditModal = null,
  onDelete = null,
  onChangeState = null,
  nivelModalidadFilter = null,
  gestionEducativaFilter = null,
  departamentoFilter = null,
  provinciaFilter = null,
  distritoFilter = null
) {
  return [
    {
      title: "N.º",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },
    {
      title: "Código modular",
      dataIndex: "codigo",
      key: "codigo",
      sorter: (a, b) => a.codigo.localeCompare(b.codigo)
    },
    {
      title: "Nombre",
      dataIndex: "denominacion",
      key: "denominacion",
      sorter: (a, b) => a.denominacion.localeCompare(b.denominacion)
    },
    {
      title: "Nivel / Modalidad",
      dataIndex: "nivelmodalidad",
      key: "nivelmodalidad",
      filters: nivelModalidadFilter.map((nivelmodalidad) => ({
        text: nivelmodalidad,
        value: nivelmodalidad
      })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.nivelmodalidad) {
          return record.nivelmodalidad.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => a.nivelmodalidad.localeCompare(b.nivelmodalidad),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Tipo de Gestión",
      dataIndex: "gestioneducativa",
      key: "gestioneducativa",
      filters: gestionEducativaFilter.map((gestioneducativa) => ({
        text: gestioneducativa,
        value: gestioneducativa
      })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.gestioneducativa) {
          return record.gestioneducativa.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => a.gestioneducativa.localeCompare(b.gestioneducativa),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Direccion",
      dataIndex: "direccion",
      key: "direccion",
      sorter: (a, b) => a.direccion.localeCompare(b.direccion),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Departamento",
      dataIndex: "departamento",
      key: "departamento",
      filters: departamentoFilter.map((departamento) => ({
        text: departamento,
        value: departamento
      })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.departamento) {
          return record.departamento.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => a.departamento.localeCompare(b.departamento),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Provincia",
      dataIndex: "provincia",
      key: "provincia",
      filters: provinciaFilter.map((provincia) => ({
        text: provincia,
        value: provincia
      })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.provincia) {
          return record.provincia.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => a.provincia.localeCompare(b.provincia),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Distrito",
      dataIndex: "distrito",
      key: "distrito",
      filters: distritoFilter.map((distrito) => ({
        text: distrito,
        value: distrito
      })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.distrito) {
          return record.distrito.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => a.distrito.localeCompare(b.distrito),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Acciones",
      key: "action",
      render: (record) => (
        <span>
          <Tooltip title="Editar">
            <Button icon={<EditOutlined />} onClick={() => showEditModal(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Eliminar">
            <Button icon={<DeleteOutlined />} onClick={() => onDelete(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Habilitar/desabilitar">
            <Switch size="small" checked={record.activo} onClick={() => onChangeState(record)} />
          </Tooltip>
        </span>
      )
    }
  ];
}
