import { Fragment } from "react";
import { Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { resetUserPassword } from "../../api/persona";

/**
 * PersonaResetPassword
 * @param {Object} user
 * @param {Function} closeModal
 */
function PersonaResetPassword({ record, closeModal }) {
  const history = useHistory();

  const handleResetPassword = async (data) => {
    try {
      const res = await resetUserPassword(data?.identidad, {
        password: data.documentoregistro
      });
      message.success(res.message);

      closeModal();
      history.replace("/app/persona");
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Fragment>
      <p className="mb-5">
        ¿Resetear la contraseña de
        <span className="text-blue-500 my-5">
          {" " + record?.nombre + " " + record?.apellidopaterno + " " + record?.apellidomaterno}
        </span>
        ?, el estudiante deberá ingresar con su número de DNI.
      </p>
      <Button type="primary" block htmlType="submit" onClick={() => handleResetPassword(record)}>
        Resetear contraseña
      </Button>
    </Fragment>
  );
}
export default PersonaResetPassword;
