import React, { Fragment } from "react";
import { Button, Drawer } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import MenuItems from "../MenuItems";

/**
 * MenuLateral
 * @param {Boolean} isMenuCollapsed
 * @param {Function} toggleMenuCollapsed
 * @param {Boolean} isDrawerVisible
 * @param {Function} closeDrawer
 */
function MenuLateral(props) {
  const { isMenuCollapsed, toggleMenuCollapsed, isDrawerVisible, closeDrawer } =
    props;

  return (
    <Fragment>
      <div>
        <Button
          type="primary"
          onClick={toggleMenuCollapsed}
          className="mb-4 ml-6 mt-5"
        >
          {React.createElement(
            isMenuCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </Button>
        <MenuItems isMenuCollapsed={isMenuCollapsed} />
      </div>
      <Drawer
        title="Menu de opciones"
        placement="left"
        closable={true}
        onClose={closeDrawer}
        visible={isDrawerVisible}
      >
        <MenuItems closeDrawer={closeDrawer} />
      </Drawer>
    </Fragment>
  );
}

export default MenuLateral;
