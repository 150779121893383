import { Fragment, useState, useEffect } from "react";
import { notification, Button, Modal, Select } from "antd";
import { TitleHeader, Searcher, TableAntd } from "../../components";
import { ReloadOutlined, ExclamationCircleOutlined, FieldNumberOutlined } from "@ant-design/icons";
import { getPostulantes, executeQuery } from "../../api/sistema";
import { createColumns } from "./formatTable";
import { SELECT_ESTADOS, INSTITUCION, OBJ_ROLES } from "../../utils/shared";
import { openInNewTab, getPeriodo, getListaPeriodosInscripcionActiva } from "../../utils/functions";
import { useHistory } from "react-router-dom";
import PostulanteRegister from "./PostulanteRegister";
import PCantidadProgramaEstudio from "./PCantidadProgramaEstudio";
import { useAuth } from "../../utils/useAuth";

const { Option } = Select;
const PATH_MODELO = "/app/postulante";
const idPeriodo = getPeriodo()?.idperiodo || 0;
const idInstitucion = getPeriodo()?.idinstitucion || 0;
const idFacultad = getPeriodo()?.idfacultad || 0;
const nombrePeriodo = getPeriodo()?.denominacion || 0;

function Postulante() {
  const history = useHistory();
  const { rol } = useAuth();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [dataToTable, setDataToTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [totalPostulantes, setTotalPostulantes] = useState(0);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [onSelect, setOnSelect] = useState("");
  const [onSelectAsignado, setOnSelectAsignado] = useState("");
  const [revisores, setRevisores] = useState([]);
  const [isDisabledInscribirPostulante, setIsDisabledInscribirPostulante] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");

  const [programaEstudio, setProgramaEstudio] = useState([]);
  const [onSelectProgramaEstudio, setOnSelectProgramaEstudio] = useState("");
  const [statistics, setStatistics] = useState({
    incompleto: 0,
    pendiente: 0,
    observado: 0,
    aceptado: 0,
    llenadovpi: 0,
    inscrito: 0
  });

  useEffect(() => {
    fetchDataToPage();
  }, []);

  useEffect(() => {
    fetchDataToTable();
    fetchDataEstatistics();
    fetchPeriodosActivos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pageSize, page, search, onSelect, onSelectAsignado, onSelectProgramaEstudio]);

  useEffect(() => {
    setTotalPostulantes(totalData - statistics.incompleto);
  }, [totalData, statistics]);

  const fetchDataToTable = async () => {
    setIsLoading(true);
    getPostulantes({
      take: pageSize,
      page,
      idperiodo: idPeriodo,
      search,
      idestado: onSelect,
      asignado: onSelectAsignado,
      idprogramaestudio: onSelectProgramaEstudio
    })
      .then((res) => {
        setDataToTable(res.payload.data);
        setTotalData(res.payload.count);
      })
      .catch((err) => {
        notification.error({
          message: "Problemas con la solicitud",
          description: err.message
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchPeriodosActivos = async () => {
    const periodos_inscripcion_activa = await getListaPeriodosInscripcionActiva();

    const periodoActual = periodos_inscripcion_activa.filter((p) => p.id === idPeriodo);
    setIsDisabledInscribirPostulante(!Boolean(periodoActual.length));
  };

  const fetchDataToPage = async () => {
    executeQuery({
      query: "get_personales_revisores"
    })
      .then((res) => {
        setRevisores(res.payload);
      })
      .catch((err) => {
        console.log(err);
      });

    executeQuery({
      query: "get_programas_estudio_by_idfacultad",
      variables: [{ name: "IDFACULTAD", value: idFacultad }]
    })
      .then((res) => {
        setProgramaEstudio(res.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDataEstatistics = async () => {
    executeQuery({
      query: "get_all_estado_postulante_by_periodo",
      variables: [
        { name: "IDPERIODO1", value: idPeriodo },
        { name: "IDPERIODO2", value: idPeriodo },
        { name: "IDPERIODO3", value: idPeriodo },
        { name: "IDPERIODO4", value: idPeriodo },
        { name: "IDPERIODO5", value: idPeriodo },
        { name: "IDPERIODO6", value: idPeriodo }
      ]
    })
      .then((data) => {
        const item = data.payload[0];
        setStatistics(item);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeState = (record) => {
    const activateMsg = record.activo ? "desactivar" : "activar";

    Modal.confirm({
      title: `¿Estás seguro de ${activateMsg} al postulante '${record.nombre}?'`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, " + activateMsg,
      cancelText: "Cancelar",
      onOk() {
        console.log("Execute update");
      }
    });
  };

  const handleOpenInNewTab = async (record) => {
    openInNewTab(`${PATH_MODELO}/${record.idpostulante}`);
  };

  const handleGoDetail = (record) => {
    history.push(`${PATH_MODELO}/${record.idpostulante}`);
  };

  const columnsToTable = createColumns(onChangeState, handleOpenInNewTab, handleGoDetail);

  const NumberStatus = ({ quantity, label, color }) => (
    <div className="text-base font-semibold mr-4" style={{ color }}>
      {quantity} {label}
    </div>
  );

  const showAddModal = async () => {
    setModalTitle("Nuevo postulante");
    setModalContent(<PostulanteRegister closeModal={closeModal} />);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const showCantidadPostulantesModal = async () => {
    setModalTitle(nombrePeriodo + ": Número de postulantes x programa de estudio");
    setModalContent(<PCantidadProgramaEstudio />);
    setIsModalVisible(true);
  };

  return (
    <Fragment>
      <TitleHeader
        title={
          <div className="flex justify-between">
            <div className="flex gap-2 items-center">
              <h1 className="font-bold text-lg text-blue-500">({totalPostulantes}) Postulantes</h1>
              <Button
                size="small"
                icon={<ReloadOutlined />}
                onClick={() => setReload(!reload)}
              ></Button>
            </div>

            {rol === OBJ_ROLES.SUPERADMIN && (
              <Button
                className="ml-4"
                disabled={isDisabledInscribirPostulante}
                onClick={showAddModal}
              >
                Nuevo postulante
              </Button>
            )}
          </div>
        }
      />

      <div className="lg:flex justify-between">
        <div className="lg:flex w-full  lg:w-3/4 justify-start">
          <NumberStatus quantity={statistics.incompleto} label="Incompletos" color="gray" />
          <NumberStatus quantity={statistics.pendiente} label="Pendientes" color="skyblue" />
          <NumberStatus quantity={statistics.observado} label="Observados" color="orange" />
          <NumberStatus quantity={statistics.aceptado} label="Aceptados" color="teal" />
          <NumberStatus quantity={statistics.llenadovpi} label="Llenado_VPI" color="purple" />
          <NumberStatus quantity={statistics.inscrito} label="Inscritos" color="green" />
        </div>

        <div className="w-full lg:w-1/3 flex justify-center">
          {idInstitucion === INSTITUCION.SESP && (
            <div className="flex w-2/5">
              <Button
                className="w-1/6"
                icon={<FieldNumberOutlined />}
                onClick={() => showCantidadPostulantesModal()}
              />
              <Select
                onSelect={(value) => setOnSelectProgramaEstudio(value)}
                defaultValue=""
                className="w-5/6"
              >
                <Option value="">TODOS</Option>
                {programaEstudio.map((item, index) => (
                  <Option key={index} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
          )}

          <Select onSelect={(value) => setOnSelect(value)} defaultValue="" className="w-1/5">
            {SELECT_ESTADOS.map((item, index) => (
              <Option key={index} value={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>

          {idInstitucion === INSTITUCION.DIAD && (
            <Select
              onSelect={(value) => setOnSelectAsignado(value)}
              defaultValue=""
              className="w-1/5"
            >
              <Option value="">TODOS</Option>
              {revisores.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          )}

          <Searcher onSearch={(value) => setSearch(value)} placeholder="Buscar" className="w-2/5" />
        </div>
      </div>

      <TableAntd
        columns={columnsToTable}
        dataSource={dataToTable}
        isLoading={isLoading}
        total={totalData}
        setPageSize={setPageSize}
        setPage={setPage}
        pageSize={pageSize}
      />

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        maskClosable={false}
        width={800}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
}

export default Postulante;
