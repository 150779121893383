import { Result, Button } from "antd";
import { useHistory } from "react-router-dom";

function Error404() {
  const history = useHistory();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Lo sentimos, la página que buscas no existe."
      extra={
        <Button type="primary" onClick={() => history.push("/")}>
          Regresar a Inicio
        </Button>
      }
    />
  );
}

export default Error404;
