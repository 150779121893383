import { useState, useEffect } from "react";
import { Form, Input, message, Button, Select } from "antd";
import * as AppApi from "../../api/sistema";
import { TIPO_CATALOGO } from "../../utils/shared";
import { getDataCatalogo } from "../../utils/functions";

const { Option } = Select;

const DatosAcademicos = ({ record, isDisabled }) => {
  const [departamento, setDepartamento] = useState("");
  const [provincia, setProvincia] = useState("");
  const [distrito, setDistrito] = useState("");
  const [centroestudio, setCentroestudio] = useState("");

  const [arrayProvincia, setArrayProvincia] = useState([]);
  const [arrayDistrito, setArrayDistrito] = useState([]);
  const [arrayCentroestudio, setArrayCentroestudio] = useState([]);
  const [arrayDepartamentos, setArrayDepartamentos] = useState([]);

  const [catalogo, setCatalogo] = useState({
    situacionpostulante: []
  });

  useEffect(() => {
    fetchCatalogo();
  }, []);

  useEffect(() => {
    fetchDepartamentos();
    if (record.idcentroestudio) {
      fetchUbicacionCentroEstudio(record.idcentroestudio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCatalogo = async () => {
    const situacionpostulante = await getDataCatalogo(TIPO_CATALOGO.situacionpostulante);

    setCatalogo({
      situacionpostulante
    });
  };

  const fetchDepartamentos = () => {
    AppApi.executeQuery({
      query: "get_departamentos"
    }).then((data) => {
      setArrayDepartamentos(data.payload);
    });
  };

  // ---- datos centro estudio ----
  const fetchUbicacionCentroEstudio = async (idcentroestudio) => {
    AppApi.executeQuery({
      query: "get_ubicacion_geografica_centro_estudio",
      variables: [{ name: "IDCENTROESTUDIO", value: idcentroestudio }]
    }).then((data) => {
      const idubicaciongeografica = data.payload[0]?.idubicaciongeografica;
      if (idubicaciongeografica) {
        getDefaults(idubicaciongeografica, idcentroestudio);
      }
    });
  };

  const getDefaults = (idubicaciongeografica, idcentroestudio) => {
    const iddepartamento = idubicaciongeografica.slice(0, 2);
    const idprovincia = idubicaciongeografica.slice(0, 4);
    const iddistrito = idubicaciongeografica;

    fetchProvincia(iddepartamento);
    fetchDistrito(idprovincia);
    fetchCentroestudio(iddistrito);

    setDepartamento(iddepartamento);
    setProvincia(idprovincia);
    setDistrito(iddistrito);
    setCentroestudio(idcentroestudio);
  };

  const fetchProvincia = async (iddepartamento) => {
    AppApi.executeQuery({
      query: "get_provincias_by_departamento",
      variables: [{ name: "IDDEPARTAMENTO", value: iddepartamento }]
    }).then((data) => {
      setArrayProvincia(data.payload);
    });
  };

  const fetchDistrito = async (idprovincia) => {
    AppApi.executeQuery({
      query: "get_distritos_by_provincia",
      variables: [{ name: "IDPROVINCIA", value: idprovincia }]
    }).then((data) => {
      setArrayDistrito(data.payload);
    });
  };

  const fetchCentroestudio = async (iddistrito) => {
    AppApi.executeQuery({
      query: "get_centro_estudio_by_distrito",
      variables: [{ name: "IDDISTRITO", value: iddistrito }]
    }).then((data) => {
      setArrayCentroestudio(data.payload);
    });
  };

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  // ------- Conexion con api -------
  const sendDataToServer = async (data) => {
    console.log("data", data);

    const params = {
      nombretabla: "pos_postulante",
      nombreid: "idpostulante",
      valorid: record?.idpostulante
    };

    data.idcentroestudio = centroestudio;

    AppApi.executeUpdate(params, data)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Form.Item
          label="Departamento"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            disabled={isDisabled}
            value={departamento}
            onSelect={(value) => {
              setDepartamento(value);
              fetchProvincia(value.slice(0, 2));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDepartamentos.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Provincia" rules={[{ required: true, message: "El campo es requerido" }]}>
          <Select
            disabled={isDisabled}
            value={provincia}
            onSelect={(value) => {
              setProvincia(value);
              fetchDistrito(value.slice(0, 4));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayProvincia.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Distrito" rules={[{ required: true, message: "El campo es requerido" }]}>
          <Select
            disabled={isDisabled}
            value={distrito}
            onSelect={(value) => {
              setDistrito(value);
              fetchCentroestudio(value);
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDistrito.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>

        <div className="form-group md:col-span-2">
          <Form.Item
            label="Nombre del colegio"
            rules={[{ required: true, message: "El campo es requerido" }]}
          >
            <Select
              disabled={isDisabled}
              value={centroestudio}
              onSelect={(value) => {
                setCentroestudio(value);
              }}
            >
              <Option value="" disabled>
                Seleccione
              </Option>
              {arrayCentroestudio.map((item) => (
                <Option value={item.id}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Form.Item label="Año de egreso" name="anioegreso" initialValue={record?.anioegreso}>
          <Input type="number" disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Situación de postulante"
          name="idsituacionpostulante"
          initialValue={record?.idsituacionpostulante || ""}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.situacionpostulante.map((item) => (
              <Option value={item.id}>{item.label}</Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DatosAcademicos;
