import { Result } from "antd";

export default function RParticipante({ response }) {
  if (!response.found) {
    return <Result status="error" title="Lo sentimos" subTitle="No encontramos al postulante" />;
  } else {
    const { data } = response;

    return (
      <div className="text-center">
        <h1
          className={
            "text-lg text-center p-1 text-white font-semibold " +
            (response.duplicado ? "bg-red-400" : "bg-green-500")
          }
        >
          {data?.nombre + " " + data?.apellidopaterno + " " + data?.apellidomaterno}
        </h1>
        {response.duplicado && (
          <p className="text-sm my-2 text-red-600">YA SE ENTREGÓ CONSTANCIA</p>
        )}
        <div className="text-sm my-2">
          Nro DOC: <span className="font-bold">{data?.documentoregistro || ""}</span>
        </div>
        <div className="flex flex-col items-center">
          <p className="font-semibold text-gray-500 mt-3">CANAL</p>
          <h2 className="text-xl font-bold">{data?.idcanal || ""}</h2>

          <p className="font-semibold text-gray-500 mt-3">CARRERA</p>
          <h2 className="text-xl font-bold">{data?.carrera || ""}</h2>
        </div>
      </div>
    );
  }
}
