import { useState, useEffect } from "react";
import { Form, Input, Button, message, Select } from "antd";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";
import { TIPO_CATALOGO } from "../../utils/shared";
import { getDataCatalogo } from "../../utils/functions";

const { Option } = Select;

function RequisitoAddEdit({ record = null, closeModal }) {
  const history = useHistory();
  const [catalogo, setCatalogo] = useState({
    tiporequisito: []
  });

  useEffect(() => {
    fetchCatalogo();
  }, []);

  const fetchCatalogo = async () => {
    const tiporequisito = await getDataCatalogo(TIPO_CATALOGO.tiporequisito);

    setCatalogo({
      tiporequisito
    });
  };

  /**
   * Registra o edita un registro
   */
  const sendDataToServer = async (data) => {
    try {
      let response = null;

      if (record) {
        const params = {
          nombretabla: "ads_requisito",
          nombreid: "idrequisito",
          valorid: record?.idrequisito
        };

        response = await AppApi.executeUpdate(params, data);
      } else {
        response = await AppApi.executeCreate({ nombretabla: "ads_requisito" }, data);
      }

      message.success(response.message);
      history.replace("/app/requisito");
      closeModal();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={sendDataToServer}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
        <Form.Item
          label="Denominación"
          name="denominacion"
          initialValue={record?.denominacion}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Abreviatura"
          name="abreviatura"
          initialValue={record?.abreviatura}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Descripción"
          name="descripcion"
          initialValue={record?.descripcion}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Descripción extra"
          name="descripcionextra"
          initialValue={record?.descripcionextra}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Formato"
          name="formato"
          initialValue={record?.formato}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>

            <Option value="pdf">PDF</Option>

            <Option value="jpg">JPG</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Tipo requisito"
          name="idtiporequisito"
          initialValue={record?.idtiporequisito}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.tiporequisito.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}

export default RequisitoAddEdit;
