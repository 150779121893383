import { Form, Input, message, Button, Select } from "antd";
import { formLayout, ROLES_AVAILABLE } from "../../utils/shared";
import { addOne } from "../../api/user";
import { useHistory } from "react-router-dom";

const { Option } = Select;

export default function AddUser({ closeModal }) {
  const history = useHistory();

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  // ------- Agrega nuevo usuario -------
  const sendDataToServer = async (data) => {
    try {
      const response = await addOne(data);
      message.success(response.message);
      history.replace("/app/usuario");
      closeModal();
    } catch (err) {
      message.error(err.message);
    }
  };

  const onFinishFailed = ({ values }) => {
    if (Number(values.documentoregistro) < 1) {
      message.error("Número de DNI no válido");
    }
  };

  return (
    <Form
      {...formLayout.formItemLayout}
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Usuario"
        name="usuario"
        rules={[
          {
            required: true,
            message: "El campo usuario es necesario"
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Número de DNI"
        name="documentoregistro"
        rules={[
          {
            required: true,
            message: "El campo DNI es necesario"
          },
          {
            min: 8,
            message: "El valor debe tener mínimo 8 caracteres"
          }
        ]}
      >
        <Input type="number" />
      </Form.Item>

      <Form.Item
        label="Nombres"
        name="nombre"
        rules={[{ required: true, message: "El nombre es necesario" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Apellidos"
        name="apellido"
        rules={[{ required: true, message: "El apellido es necesario" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label="Correo" name="email">
        <Input type="email" />
      </Form.Item>

      <Form.Item label="Celular" name="celular">
        <Input />
      </Form.Item>

      <Form.Item
        label="Contraseña"
        name="password"
        rules={[
          { required: true, message: "La contraseña es necesaria" },
          {
            min: 6,
            message: "La contraseña debe tener como mínimo 6 caracteres"
          }
        ]}
      >
        <Input type="password" />
      </Form.Item>

      <Form.Item
        label="Rol"
        name="rol"
        rules={[{ required: true, message: "El rol es necesario" }]}
        initialValue="organizador"
      >
        <Select>
          {ROLES_AVAILABLE.map((rol) => (
            <Option value={rol.rol}>{rol.nombre}</Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Periodos" name="periodos">
        <Input placeholder="84,85 (Dejar en blanco para todos)" />
      </Form.Item>

      <Form.Item {...formLayout.tailFormItemLayout}>
        <Button type="primary" block htmlType="submit">
          Registrar
        </Button>
      </Form.Item>
    </Form>
  );
}
