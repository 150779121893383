import { Fragment, useState } from "react";
import { Button, Radio } from "antd";
import ButtonDownload from "../ButtonDownload";
import {
  EyeOutlined,
  DownloadOutlined,
  CheckOutlined,
  CloseOutlined,
  MinusOutlined
} from "@ant-design/icons";
import { openInNewTab, viewFileByUrl } from "../../utils/functions";

const Adjuntado = (props) => {
  const {
    miniatura,
    title,
    filepath,
    isFileOk,
    changeAttachedState,
    downloadFileName,
    onDownload,
    isDisabled,
    valorid
  } = props;
  const [stateUrlOk, setstateUrlOk] = useState(isFileOk);

  const previewFile = () => {
    openInNewTab(viewFileByUrl(filepath));
  };

  return (
    <div className="bg-white p-3 flex my-2 rounded-lg shadow">
      <div className="w-16 pr-5 m-auto">
        <img
          className="rounded"
          alt={title || "image"}
          src={
            miniatura ||
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG0AAABPCAYAAADhnEtrAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAQxSURBVHhe7ZrJSiNRFIZPnIdooiK6cNqJICqCgihOoIjYwaALN4qbFnwGH0AfQ3wAN25tXPoGPoDuWuMUoyZO7X/IpdPpBkVMvH/6fFB0khpi11fn3P9WJZBIJF7EoKIo/a9BhEkjxKQRYtIIMWmEmDRCTBohJo0Qk0aISSPEpBFi0ggxaYSYNEJMGiEmjRCTRohJI8SkEWLSCDFphJg0QkwaISaNEJNGiPe/MH55yf+fFwgE0q/8xGtpEFZaWirFxcXpT3LP8/OzpFIpr8V5Kw3CysvLJRaLyc3NjRQV5b6TQ1hlZaU0NDTI4+Ojt+K8lAZhZWVlcnr6UzY3tyQUCqXX5B5cIBsbGxIOh72tOG+lVVRUyPHxsezs7EgwGEyvyS0QdH19LcvLy9LR0SH39/deSvM6PeKEvRVEsP6tbd6LO04+x9CPQBv5cYJRCWhnd3d3Ogb9L1BKgzCMN+3t7TI7OysDAwNSVVUlDw8P6S0KG0ppkNPc3CwLCwsyODgoMzMzMjw8/K52WghQSnt6epLGxkadw70GKZXY1NSkiROxvdChlIY528XFhVZVdXW1yru6upJkMpmX+dxXQ/k/xKT75ORE9vb25OjoSA4PD+Xg4Ieuy47oEIuQgmoslNZJe1lCHITt7u7K/v6+xOMJrbhsEFgw/qF9Foo4WmmoKEzAMY5B1r+EYSqA9rm0tCSRyDedpBdCwqQeABA6sNTU1GhFZeLmbYuLi1JSUiK1tWGJRqO6HYIMM7TSIOv29lbm5uY0+uMuhqsiSIG0SCSirRGT8FQqKS0tLTqvQ+JkTpmU0nDCIWJ0dFS6u7t1vJqamtKWCVmopsnJSenp6dFE6YAsTMTHxsb0c1ZxdNKcsK6uLhkZGdFxC+97e3tVyOXlpfT19Ul/f79+juABmS5VYnvs19nZqa8ZxdFJQxW1tbXJ9PS0CsFJx4LPIWNiYkLGx8d1W7TJzCkAXmNb7If9cRzGYEIlzZ3g+fmo3mvMvEkMQRCC1ofxza2DoMwFYB1SJYIJXrOJo5GGk4tYv7b2XUKhWn2PVJi5uKrCXZHsddkL9sfD1fX1dT0u3rNAIw3j09DQ0GuFBOXsLKahAo9lPrpgfxwHFYfj4vgseP3kGreqtre3dR6GFlZfX/9Hm/sMXEg5Pz/XiovH47K6uiqtra0qMnNM9AWqnxvksoWhZQJU4crKioYUX6V53R5xvlBhCBkuCeZqcd+BFIr3n1nNn4230lxQwHMzpMF8LAgw+L66ujqvg4nXv3tEy8IYg5aFqz/XYMqAYIKLBdLy8Z0fwVtpwInL54NNiPNZGPBaGviKscVnYcB7acbf0Eyujd+YNEJMGiEmjRCTRohJI8SkEWLSCDFphJg0QkwaISaNEJNGiEkjxKQRYtIIMWmEmDRCTBohJo0Qk0aISSPEpNEh8gsH+kq3nbGgPQAAAABJRU5ErkJggg=="
          }
        />
      </div>
      <div className="flex-1">
        <h2 className="font-bold text-gray-700 my-0">{title}</h2>
        {filepath ? (
          <Fragment>
            <Button icon={<EyeOutlined />} onClick={previewFile} />
            <ButtonDownload
              icon={<DownloadOutlined />}
              fileName={downloadFileName}
              onDownload={onDownload}
            />
          </Fragment>
        ) : (
          <p className="inline text-gray-400">No adjuntado</p>
        )}
        {filepath && (
          <Radio.Group
            className="float-right"
            buttonStyle="solid"
            disabled={isDisabled}
            value={stateUrlOk}
            onChange={(e) => {
              changeAttachedState(valorid, e.target.value, title);
              setstateUrlOk(e.target.value);
            }}
          >
            <Radio.Button
              value={null}
              style={{
                background: stateUrlOk === null && "#6b7280"
              }}
            >
              <MinusOutlined />
            </Radio.Button>
            <Radio.Button
              value={false}
              style={{
                background: stateUrlOk === 0 && "#f87171"
              }}
            >
              <CloseOutlined />
            </Radio.Button>
            <Radio.Button
              value={true}
              style={{
                background: stateUrlOk === 1 && "#34d399"
              }}
            >
              <CheckOutlined />
            </Radio.Button>
          </Radio.Group>
        )}
      </div>
    </div>
  );
};

export default Adjuntado;
