import LayoutAdmin from "../layouts/LayoutAdmin";
import LayoutInicio from "../layouts/LayoutInicio";

import Users from "../pages/Users";
import MiCuenta from "../pages/Users/MiCuenta";
import Reportes from "../pages/Reportes";
import Login from "../pages/Login";
import Error404 from "../pages/404";
import Home from "../pages/Home";
import Periodos from "../pages/Periodos";

import Persona from "../pages/Persona";
import Postulante from "../pages/Postulante";
import Configuracion from "../pages/Configuracion";
import Revision from "../pages/Postulante/Revision";
import Ingresante from "../pages/Ingresante";
import IRevision from "../pages/Ingresante/Revision";
import Constancia from "../pages/Constancia/Constancia";
import Registro from "../pages/Constancia/Registro";
import Proceso from "../pages/Proceso/Proceso";
import Catalogo from "../pages/Catalogo";
import Requisito from "../pages/Requisito/Requisito";
import RequisitoExamen from "../pages/RequisitoExamen";
import ModalidadPago from "../pages/ModalidadPago/ModalidadPago";
import ProgramaEstudio from "../pages/ProgramaEstudio";
import Canal from "../pages/Canal";
import CentroEstudio from "../pages/CentroEstudio";

const routes = [
  {
    path: "/app",
    component: LayoutAdmin,
    exact: false,
    routes: [
      {
        path: "/app",
        component: Home,
        exact: true
      },
      {
        path: "/app/usuario",
        component: Users,
        exact: true
      },
      {
        path: "/app/reporte",
        component: Reportes,
        exact: true
      },
      {
        path: "/app/persona",
        component: Persona,
        exact: true
      },
      {
        path: "/app/postulante",
        component: Postulante,
        exact: true
      },
      {
        path: "/app/postulante/:idpostulante",
        component: Revision,
        exact: true
      },
      {
        path: "/app/ingresante",
        component: Ingresante,
        exact: true
      },
      {
        path: "/app/constancia",
        component: Constancia,
        exact: true
      },
      {
        path: "/app/constancia/registro",
        component: Registro,
        exact: true
      },
      {
        path: "/app/ingresante/:idingresante",
        component: IRevision,
        exact: true
      },
      {
        path: "/app/periodo",
        component: Periodos,
        exact: true
      },
      {
        path: "/app/configuracion",
        component: Configuracion,
        exact: true
      },
      {
        path: "/app/micuenta",
        component: MiCuenta,
        exact: true
      },
      {
        path: "/app/proceso",
        component: Proceso,
        exact: true
      },
      {
        path: "/app/catalogo",
        component: Catalogo,
        exact: true
      },
      {
        path: "/app/requisito",
        component: Requisito,
        exact: true
      },
      {
        path: "/app/requisitoexamen",
        component: RequisitoExamen,
        exact: true
      },
      {
        path: "/app/modalidadpago",
        component: ModalidadPago,
        exact: true
      },
      {
        path: "/app/programaestudio",
        component: ProgramaEstudio,
        exact: true
      },
      {
        path: "/app/canal",
        component: Canal,
        exact: true
      },
      {
        path: "/app/centroestudio",
        component: CentroEstudio,
        exact: true
      },
      {
        component: Error404
      }
    ]
  },

  {
    path: "/",
    component: LayoutInicio,
    exact: false,
    routes: [
      {
        path: "/",
        component: Login,
        exact: true
      },
      {
        component: Error404
      }
    ]
  }
];

export default routes;
