import { Button, Switch, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

/**
 * createColumns
 * Complemento para mostrar las tablas tanto como en antd y modo responsive
 * @param {Function} showEditModal
 * @param {Function} onDelete
 * @param {Number} page
 */
export function createColumns(
  page = 1,
  showEditModal = null,
  onDelete = null,
  onChangeState = null
) {
  return [
    {
      title: "N.º",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },
    {
      title: "Denominación",
      dataIndex: "denominacion",
      key: "denominacion",
      sorter: (a, b) => a.denominacion.localeCompare(b.denominacion)
    },
    {
      title: "Abreviatura",
      dataIndex: "abreviatura",
      key: "abreviatura",
      sorter: (a, b) => a.abreviatura.localeCompare(b.abreviatura),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      key: "descripcion",
      sorter: (a, b) => a.descripcion.localeCompare(b.descripcion),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Desc. exta",
      dataIndex: "descripcionextra",
      key: "descripcionextra",
      sorter: (a, b) => a.descripcionextra.localeCompare(b.descripcionextra),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Formato",
      dataIndex: "formato",
      key: "formato",
      sorter: (a, b) => a.formato.localeCompare(b.formato),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Acciones",
      key: "action",
      render: (record) => (
        <span>
          <Tooltip title="Editar">
            <Button icon={<EditOutlined />} onClick={() => showEditModal(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Eliminar">
            <Button icon={<DeleteOutlined />} onClick={() => onDelete(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Habilitar/desabilitar">
            <Switch size="small" checked={record.activo} onClick={() => onChangeState(record)} />
          </Tooltip>
        </span>
      )
    }
  ];
}
