import { Fragment, useState, useEffect } from "react";
import { TitleHeader, Loading, UserCard } from "../../components";
import { useParams } from "react-router-dom";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Radio, Modal, Tabs, message, Form, Input } from "antd";
import { ESTADO, LABEL_ESTADO, INSTITUCION, OBJ_ROLES } from "../../utils/shared";
import { getPeriodo } from "../../utils/functions";
import DatosPersonales from "./RDatosPersonales";
import DatosAcademicos from "./RDatosAcademicos";
import DatosPago from "./RDatosPago";

import * as AppApi from "../../api/ingresante";
// import { viewFileByUrl } from "../../utils/functions";
import { useAuth } from "../../utils/useAuth";

const { confirm } = Modal;
// const idPeriodo = getPeriodo()?.idperiodo || 0;
const idInstitucion = getPeriodo()?.idinstitucion || 0;

const Revision = () => {
  const { usuario, rol } = useAuth();

  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [fotoPostulante, setFotoPostulante] = useState("");

  const [dataToPage, setDataToPage] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const { idingresante } = useParams();

  useEffect(() => {
    fetchDataToPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const fetchDataToPage = async () => {
    setIsLoading(true);

    AppApi.executeQuery({
      query: "get_total_informacion_ingresante",
      variables: [{ name: "IDINGRESANTE", value: idingresante }]
    })
      .then((data) => {
        const item = data.payload[0];

        setDataToPage(item);
        // setFotoPostulante(`/${item?.rutafotografia}_FTR.jpg`);

        setIsDisabled(
          item.idestado === ESTADO.aceptado ||
            item.idestado === ESTADO.llenadovpi ||
            item.idestado === ESTADO.inscrito
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const showChangeStateConfirm = (newEstadoId) => {
    confirm({
      title: `¿Estás seguro que deseas cambiar el estado a  ${LABEL_ESTADO[newEstadoId]}?`,
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const params = {
          nombretabla: "reg_ingresante",
          nombreid: "idingresante",
          valorid: dataToPage?.idingresante
        };

        AppApi.executeUpdate(params, {
          idestado: newEstadoId,
          usuariovalido: usuario.usuario,
          fechavalido: new Date()
        })
          .then((res) => {
            message.success(res.message);

            window.location.reload();
          })
          .catch((err) => {
            message.error(err.message);
          });
      },
      onCancel() {
        return null;
      }
    });
  };

  const onSubmit = (values) => {
    const params = {
      nombretabla: "reg_ingresante",
      nombreid: "idingresante",
      valorid: dataToPage?.idingresante
    };

    AppApi.executeUpdate(params, values)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Fragment>
      <TitleHeader
        title={
          <div className="flex gap-2 items-center">
            <span className="font-bold text-lg">REVISIÓN DE DOCUMENTOS</span>

            <span className="flex items-center justify-center">
              <Button
                size="small"
                icon={<ReloadOutlined />}
                onClick={() => setReload(!reload)}
              ></Button>
            </span>
          </div>
        }
        extra={
          <div className="mt-2 mr-6 font-bold text-base flex gap-2">
            <span>INGRESANTE</span>
            {" | "}
            <p>{dataToPage?.periodo}</p>
            {" | "}
            <p>{dataToPage?.modalidadingreso}</p>
          </div>
        }
      />

      <div className="lg:flex w-full">
        <div className="w-full mb-8 md:w-1/2 md:mx-auto lg:w-2/12 text-center">
          <h2 className="text-md font-bold mb-2 text-azul-700">FOTOGRAFÍA</h2>
          <UserCard
            // image={viewFileByUrl(fotoPostulante)}
            title={`${dataToPage?.apellidopaterno} ${dataToPage?.apellidomaterno}, ${dataToPage?.nombre}`}
            subTitle={`${dataToPage?.tipodocumentoregistro}: ${dataToPage?.documentoregistro}`}
            state={dataToPage?.idestado}
            codigo={(dataToPage?.codigo && "CÓDIGO: " + dataToPage?.codigo) || ""}
          />
        </div>

        <div className="w-full mb-8 lg:w-7/12 lg:px-10">
          <h2 className="text-md font-bold mb-4 text-azul-700">DATOS DEL INGRESANTE</h2>

          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="PERSONALES" key="1">
              <DatosPersonales record={dataToPage} isDisabled={isDisabled} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="ACADÉMICOS" key="2">
              <DatosAcademicos record={dataToPage} isDisabled={isDisabled} />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab="COMPLEMENTARIOS" key="3">
                <DatosComplementarios record={dataToPage} isDisabled={isDisabled} />
              </Tabs.TabPane> */}
            <Tabs.TabPane tab="FINALES" key="4">
              <DatosPago record={dataToPage} isDisabled={isDisabled} />
            </Tabs.TabPane>
          </Tabs>
        </div>

        <div className="w-full lg:w-3/12">
          <h2 className="text-md font-bold my-2 text-azul-700">OBSERVACIONES</h2>
          <Form layout="vertical" initialValues={{ remember: true }} onFinish={onSubmit}>
            <Form.Item
              label="Observación pública (SE MOSTRARÁ CUANDO ESTÉ OBSERVADO)"
              name="observacionexterno"
              initialValue={dataToPage?.observacionexterno}
            >
              <Input.TextArea
                rows={3}
                placeholder="Agrege un mensaje sobre la observación del postulante respecto a los datos o documentos"
              />
            </Form.Item>

            <Form.Item
              label="Observación local (Información para personal de la DIAD)"
              name="observacioninterno"
              initialValue={dataToPage?.observacioninterno}
            >
              <Input.TextArea
                rows={3}
                placeholder="Agrege una observación local del postulante, para registro de la DIAD"
              />
            </Form.Item>

            <Form.Item className="my-2">
              <Button type="primary" block htmlType="submit">
                Guardar cambios
              </Button>
            </Form.Item>
          </Form>

          <hr className="my-4" />
          <h2 className="text-md font-bold my-2 text-azul-700">ACTUALIZAR ESTADO</h2>

          {idInstitucion === INSTITUCION.DIAD && (
            <Radio.Group
              className="flex flex-wrap text-center"
              buttonStyle="solid"
              value={dataToPage?.idestado}
              onChange={(e) => showChangeStateConfirm(e.target.value)}
            >
              {rol === OBJ_ROLES.SUPERADMIN && (
                <Radio.Button value={ESTADO?.incompleto || ""} className="flex-1">
                  INCOMPLETO
                </Radio.Button>
              )}
              <Radio.Button value={ESTADO?.pendiente || ""} className="flex-1">
                PENDIENTE
              </Radio.Button>
              <Radio.Button value={ESTADO?.observado || ""} className="flex-1">
                OBSERVADO
              </Radio.Button>
              <Radio.Button value={ESTADO?.aceptado || ""} className="flex-1">
                FIRMADO
              </Radio.Button>
            </Radio.Group>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Revision;
