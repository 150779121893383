import CIngresante from "./CIngresante";
import { useAuth } from "../../utils/useAuth";
import { OBJ_ROLES } from "../../utils/shared";

const Constancia = () => {
  const { rol } = useAuth();

  return (
    <div className="p-4 mx-auto text-center w-full">
      <div className="">
        <p className="text-base font-bold">ENTREGA DE CONSTANCIAS</p>
        <p className="text-base font-bold text-blue-500 uppercase my-2">
          PROCESO DE ADMISIÓN 2024-I
        </p>
      </div>

      {rol === OBJ_ROLES.SUPERADMIN && (
        <div className="w-full">
          <CIngresante />
        </div>
      )}
    </div>
  );
};

export default Constancia;
