import React, { useState, useRef } from "react";
import { Form, Input, message, Button } from "antd";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";

function PersonaRegister({ closeModal }) {
  const [documentoregistro, setDocumentoRegistro] = useState("");
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const formRef = useRef(null);
  const history = useHistory();

  const onSearch = async () => {
    setIsLoadingSearch(true);

    try {
      const data = await AppApi.executePost("/api/core/reniec", {
        documentoregistro
      });
      setIsDisabled(true);
      message.success(data.message);

      if (formRef.current) {
        formRef.current.setFieldsValue({
          nombre: data.payload.nombre,
          apellidopaterno: data.payload.apellidopaterno,
          apellidomaterno: data.payload.apellidomaterno
        });
      }
    } catch (error) {
      setIsDisabled(false);
      message.warn(
        "El documento de identidad no fue encontrado, asegrúrate que hayas escrito bien o regístrelo manualmente"
      );
    } finally {
      setIsLoadingSearch(false);
    }
  };

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  const sendDataToServer = async (data) => {
    console.log("sendDataToServer", data);

    if (data.password !== data.repeatpassword) {
      message.error("Las contraseñas no coinciden");
      return;
    }

    data.email = data.email.toLowerCase();
    delete data.repeatpassword;

    console.log(data);

    AppApi.executePost("/api/auth/register", data)
      .then((res) => {
        console.log(res);
        closeModal();
        message.success(res.message);
        history.replace("/app/persona");
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {});
  };

  return (
    <Form layout="vertical" onFinish={onFinish} ref={formRef}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        <Form.Item
          label={
            <>
              <p>Número de documento</p> &nbsp;
              <Button
                size="small"
                icon={<SearchOutlined />}
                loading={isLoadingSearch}
                onClick={onSearch}
              />
            </>
          }
          name="documentoregistro"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input onChange={(e) => setDocumentoRegistro(e.target.value)} />
        </Form.Item>

        <Form.Item
          label="Nombre"
          name="nombre"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Apellido paterno"
          name="apellidopaterno"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Apellido materno"
          name="apellidomaterno"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Correo electrónico"
          name="email"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          label="Contraseña"
          name="password"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input type="password" />
        </Form.Item>

        <Form.Item label="Repite contraseña" name="repeatpassword">
          <Input type="password" />
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}

export default PersonaRegister;
