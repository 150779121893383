import { Fragment, useState, useEffect } from "react";
import { TitleHeader } from "../../components";
import { notification, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import * as AppApi from "../../api/sistema";
import { getPeriodo } from "../../utils/functions";

const idPeriodo = getPeriodo()?.idperiodo || 0;

function Home() {
  const [dashboard, setDashboard] = useState({
    totalPersona: 0,
    totalPostulante: 0
  });
  const [reload, setReload] = useState(false);

  const fetchDataToPage = async () => {
    try {
      AppApi.executeQuery({
        query: "get_data_dashboard",
        variables: [{ name: "IDPERIODO", value: idPeriodo }]
      }).then((data) => {
        const entidad = data.payload[0].entidad || 0;
        const postulante = data.payload[0].postulante || 0;
        setDashboard({
          totalPersona: entidad,
          totalPostulante: postulante
        });
      });
    } catch (error) {
      notification.error({
        message: "Problemas con la solicitud",
        description: error.message
      });
    }
  };

  useEffect(() => {
    fetchDataToPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Fragment>
      <TitleHeader
        title={
          <span className="flex gap-2 items-center">
            <h1 className="font-bold">Estadísticas</h1>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => setReload(!reload)}
            ></Button>
          </span>
        }
      />
      <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4">
        {/* <div className="min-w-0 rounded-lg border-4 border-gray-200 shadow-xs bg-white dark:bg-gray-800">
          <div className="p-4 flex items-center">
            <div className="p-3 rounded-full text-yellow-500 dark:text-yellow-100 bg-yellow-100 dark:bg-yellow-500 mr-4">
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path d="M13 6a3 3 0 11-6 0 3 3 0 016 0zM18 8a2 2 0 11-4 0 2 2 0 014 0zM14 15a4 4 0 00-8 0v3h8v-3zM6 8a2 2 0 11-4 0 2 2 0 014 0zM16 18v-3a5.972 5.972 0 00-.75-2.906A3.005 3.005 0 0119 15v3h-3zM4.75 12.094A5.973 5.973 0 004 15v3H1v-3a3 3 0 013.75-2.906z"></path>
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">Personas</p>
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                {dashboard.totalPersona}
              </p>
            </div>
          </div>
        </div> */}

        <div className="min-w-0 rounded-lg border-4 border-gray-200 shadow-xs overflow-hidden bg-white dark:bg-gray-800">
          <div className="p-4 flex items-center">
            <div className="p-3 rounded-full text-green-500 dark:text-green-100 bg-green-100 dark:bg-green-500 mr-4">
              <svg fill="currentColor" viewBox="0 0 20 20" className="w-5 h-5">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.6568 14.999H7.9067C7.74021 14.999 7.59431 15.0619 7.46931 15.1867C7.3442 15.3118 7.28175 15.4575 7.28175 15.6242V19.3744C7.28175 19.5411 7.34442 19.687 7.46931 19.8119C7.59431 19.9367 7.74005 19.9994 7.9067 19.9994H11.6568C11.8233 19.9994 11.9696 19.9368 12.0941 19.8119C12.2194 19.687 12.2819 19.5411 12.2819 19.3744V15.6242C12.2819 15.4575 12.2196 15.3118 12.0941 15.1867C11.9696 15.0616 11.8233 14.999 11.6568 14.999Z"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16.5791 3.77357C16.1519 2.98668 15.5999 2.32294 14.9227 1.78113C14.2459 1.23954 13.4849 0.807023 12.6413 0.484236C11.7976 0.161558 10.9591 0 10.1256 0C6.95907 0 4.54244 1.38545 2.87564 4.15639C2.7924 4.29173 2.76366 4.43753 2.78966 4.59383C2.81582 4.74992 2.89654 4.88017 3.03194 4.98432L5.59453 6.93763C5.72993 7.02081 5.86018 7.06246 5.98524 7.06246C6.17279 7.06246 6.33413 6.979 6.46969 6.81235C7.21958 5.87497 7.77698 5.27602 8.14153 5.01546C8.58953 4.71347 9.15187 4.56253 9.82896 4.56253C10.4644 4.56253 11.0241 4.72923 11.5087 5.06231C11.993 5.39571 12.235 5.78116 12.235 6.21866C12.235 6.70804 12.105 7.10406 11.8445 7.40616C11.5842 7.70815 11.1465 7.99968 10.5318 8.28104C9.71951 8.64559 8.97438 9.21076 8.29761 9.97641C7.62035 10.7421 7.28197 11.5572 7.28197 12.4218V13.1249C7.28197 13.323 7.33637 13.5182 7.44593 13.7109C7.55539 13.9038 7.68811 14 7.84446 14H11.5945C11.7612 14 11.9069 13.9195 12.032 13.7575C12.1567 13.5965 12.2195 13.4271 12.2195 13.2503C12.2195 12.99 12.3627 12.6406 12.6489 12.2034C12.9354 11.7659 13.2921 11.427 13.7195 11.1873C14.1256 10.9582 14.443 10.7736 14.6724 10.6328C14.902 10.4923 15.2013 10.2653 15.5712 9.95299C15.941 9.64038 16.2275 9.33045 16.4303 9.02327C16.6335 8.71602 16.8159 8.31749 16.9774 7.82778C17.1392 7.33824 17.2197 6.81213 17.2197 6.24969C17.2196 5.3851 17.0059 4.55963 16.5791 3.77357Z"
                />
              </svg>
            </div>
            <div>
              <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
                Postulaciones
              </p>
              <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
                {dashboard.totalPostulante}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col md:flex-row"></div>
    </Fragment>
  );
}

export default Home;
