/**
 * USER API
 * Funciones para manejar peticiones con el servidor
 */

import * as HTTP_REQUEST from "./api";
import { handleResponse } from "../utils/functions";

const PATH_MODEL = "/api/auth/user";

export const confirmarCorreo = async (email) => {
  const response = await HTTP_REQUEST.put(PATH_MODEL + "/activar-correo/" + email);
  return handleResponse(response);
};

export const resetUserPassword = async (userId, body) => {
  const response = await HTTP_REQUEST.put(PATH_MODEL + "/password", body, userId);
  return handleResponse(response);
};
