import { useState, useEffect } from "react";
import * as AppApi from "../../api/sistema";
import { getPeriodo } from "../../utils/functions";
import { TableAntd } from "../../components";

const idPeriodo = getPeriodo()?.idperiodo || 0;
const idFacultad = getPeriodo()?.idfacultad || 0;

const columnsToTable = [
  {
    title: "N.º",
    key: "index",
    render: (value, item, index) => index + 1
  },
  {
    title: "Denominación",
    dataIndex: "programaestudio",
    key: "programaestudio",
    sorter: (a, b) => a.programaestudio.localeCompare(b.programaestudio)
  },
  {
    title: "Cantidad",
    dataIndex: "cantidad",
    key: "cantidad",
    sorter: (a, b) => a.cantidad.localeCompare(b.cantidad)
  },
  {
    title: "Porcentaje",
    dataIndex: "porcentaje",
    key: "porcentaje",
    sorter: (a, b) => a.porcentaje.localeCompare(b.porcentaje)
  }
];

function PCantidadProgramaEstudio() {
  const [isLoading, setIsLoading] = useState(false);
  const [dataToTable, setDataToTable] = useState([]);

  useEffect(() => {
    fetchDataToPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDataToPage = async () => {
    setIsLoading(true);
    AppApi.executeQuery({
      query: "get_cantidad_postulantes_programa_estudio_by_idfacultad",
      variables: [
        { name: "IDPERIODO", value: idPeriodo },
        { name: "IDFACULTAD", value: idFacultad },
        { name: "IDPERIODO2", value: idPeriodo },
        { name: "IDFACULTAD2", value: idFacultad }
      ]
    })
      .then((data) => {
        setDataToTable(data.payload);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return <TableAntd columns={columnsToTable} dataSource={dataToTable} isLoading={isLoading} />;
}

export default PCantidadProgramaEstudio;
