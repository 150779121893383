import { useState, useEffect } from "react";
import { Form, Input, message, Button, Select } from "antd";
import { getDataCatalogo } from "../../utils/functions";
import { TIPO_CATALOGO, OBJ_ROLES } from "../../utils/shared";
import * as AppApi from "../../api/ingresante";
import { useAuth } from "../../utils/useAuth";
const { Option } = Select;

const DatosPersonales = ({ record, isDisabled }) => {
  const { usuario, rol } = useAuth();

  const [catalogo, setCatalogo] = useState({
    sexo: [],
    tipo_documento: [],
    estado_civil: [],
    pais: [],
    zona: [],
    via: [],
    departamentos: []
  });
  const [arrayDepartamentos, setArrayDepartamentos] = useState([]);

  const [departamentoNacimiento, setDepartamentoNacimiento] = useState("");
  const [provinciaNacimiento, setProvinciaNacimiento] = useState("");
  const [distritoNacimiento, setDistritoNacimiento] = useState("");

  const [arrayProvinciaNacimiento, setArrayProvinciaNacimiento] = useState([]);
  const [arrayDistritoNacimiento, setArrayDistritoNacimiento] = useState([]);

  const [departamentoResidencia, setDepartamentoResidencia] = useState("");
  const [provinciaResidencia, setProvinciaResidencia] = useState("");
  const [distritoResidencia, setDistritoResidencia] = useState("");

  const [arrayProvinciaResidencia, setArrayProvinciaResidencia] = useState([]);
  const [arrayDistritoResidencia, setArrayDistritoResidencia] = useState([]);

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  useEffect(() => {
    fetchCatalogo();
    fetchDepartamentos();
  }, []);

  useEffect(() => {
    getDefaults(record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCatalogo = async () => {
    const tipo_documento = await getDataCatalogo(TIPO_CATALOGO.tipo_documento);
    const sexo = await getDataCatalogo(TIPO_CATALOGO.sexo);
    const estado_civil = await getDataCatalogo(TIPO_CATALOGO.estado_civil);
    const pais = await getDataCatalogo(TIPO_CATALOGO.pais);
    const zona = await getDataCatalogo(TIPO_CATALOGO.zona);
    const via = await getDataCatalogo(TIPO_CATALOGO.via);

    setCatalogo({
      tipo_documento,
      sexo,
      estado_civil,
      pais,
      zona,
      via
    });
  };

  const fetchDepartamentos = () => {
    AppApi.executeQuery({
      query: "get_departamentos"
    }).then((data) => {
      setArrayDepartamentos(data.payload);
    });
  };

  // ------- Conexion con api -------
  const sendDataToServer = async (data) => {
    const params = {
      nombretabla: "reg_ingresante",
      nombreid: "idingresante",
      valorid: record?.idingresante
    };

    data.idlugarnacimiento = distritoNacimiento;
    data.idlugarresidencia = distritoResidencia;
    data.usuarioactualizo = usuario.usuario; // actulizar usuario actualizo por token

    AppApi.executeUpdate(params, data)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const getDefaults = (payload) => {
    if (payload?.idlugarnacimiento) {
      const iddepartamento = payload.idlugarnacimiento.slice(0, 2);
      const idprovincia = payload.idlugarnacimiento.slice(0, 4);
      const iddistrito = payload.idlugarnacimiento;

      fetchProvinciaNacimiento(iddepartamento);
      fetchDistritoNacimiento(idprovincia);

      setDepartamentoNacimiento(iddepartamento);
      setProvinciaNacimiento(idprovincia);
      setDistritoNacimiento(iddistrito);
    }

    if (payload?.idlugarresidencia) {
      const iddepartamento2 = payload.idlugarresidencia.slice(0, 2);
      const idprovincia2 = payload.idlugarresidencia.slice(0, 4);
      const iddistrito2 = payload.idlugarresidencia;

      fetchProvinciaResidencia(iddepartamento2);
      fetchDistritoResidencia(idprovincia2);

      setDepartamentoResidencia(iddepartamento2);
      setProvinciaResidencia(idprovincia2);
      setDistritoResidencia(iddistrito2);
    }
  };

  /**
   * Actualizacion información nacimiento
   */

  const fetchProvinciaNacimiento = async (iddepartamento) => {
    AppApi.executeQuery({
      query: "get_provincias_by_departamento",
      variables: [{ name: "IDDEPARTAMENTO", value: iddepartamento, type: "string" }]
    }).then((data) => {
      setArrayProvinciaNacimiento(data.payload);
    });
  };

  const fetchDistritoNacimiento = async (idprovincia) => {
    AppApi.executeQuery({
      query: "get_distritos_by_provincia",
      variables: [{ name: "IDPROVINCIA", value: idprovincia, type: "string" }]
    }).then((data) => {
      setArrayDistritoNacimiento(data.payload);
    });
  };

  /**
   * Información residencia
   */
  const fetchProvinciaResidencia = async (iddepartamento) => {
    AppApi.executeQuery({
      query: "get_provincias_by_departamento",
      variables: [{ name: "IDDEPARTAMENTO", value: iddepartamento, type: "string" }]
    }).then((data) => {
      setArrayProvinciaResidencia(data.payload);
    });
  };

  const fetchDistritoResidencia = async (idprovincia) => {
    AppApi.executeQuery({
      query: "get_distritos_by_provincia",
      variables: [{ name: "IDPROVINCIA", value: idprovincia, type: "string" }]
    }).then((data) => {
      setArrayDistritoResidencia(data.payload);
    });
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
        <Form.Item
          label="Nombre"
          name="nombre"
          initialValue={record?.nombre}
          rules={[
            {
              required: true,
              message: "El campo es requerido"
            }
          ]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>
        <Form.Item
          label="Apellido paterno"
          name="apellidopaterno"
          initialValue={record?.apellidopaterno}
          rules={[
            {
              required: true,
              message: "El campo es requerido"
            }
          ]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Apellido materno"
          name="apellidomaterno"
          initialValue={record?.apellidomaterno}
          rules={[
            {
              required: true,
              message: "El campo es requerido"
            }
          ]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Tipo de documento"
          name="idtipodocumentoregistro"
          initialValue={record?.idtipodocumentoregistro || ""}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.tipo_documento.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Número de documento"
          name="documentoregistro"
          initialValue={record?.documentoregistro}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="celular"
          name="celular"
          initialValue={record?.celular}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item label="Ubigeo" name="idlugarubigeo" initialValue={record?.idlugarubigeo}>
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Sexo"
          name="idsexo"
          initialValue={record?.idsexo || ""}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.sexo.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Estado civil"
          name="idestadocivil"
          initialValue={record?.idestadocivil || ""}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.estado_civil.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Fecha de nacimiento"
          name="fechanacimiento"
          initialValue={record?.fechanacimiento}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input type="date" disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Correo electrónico"
          name="email"
          initialValue={record?.email}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input type="email" disabled={isDisabled || rol !== OBJ_ROLES.SUPERADMIN} />
        </Form.Item>

        <Form.Item
          label="País de nacimiento"
          name="idnacionalidad"
          initialValue={record?.idnacionalidad || ""}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.pais.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* ----------- Nacimiento ----------- */}

        <Form.Item
          label="Departamento de nacimiento"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            disabled={isDisabled}
            value={departamentoNacimiento}
            onSelect={(value) => {
              setDepartamentoNacimiento(value);
              fetchProvinciaNacimiento(value.slice(0, 2));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDepartamentos.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Provincia de nacimiento"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            disabled={isDisabled}
            value={provinciaNacimiento}
            onSelect={(value) => {
              setProvinciaNacimiento(value);
              fetchDistritoNacimiento(value.slice(0, 4));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayProvinciaNacimiento.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Ciudad de nacimiento"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            disabled={isDisabled}
            value={distritoNacimiento}
            onSelect={(value) => {
              setDistritoNacimiento(value);
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDistritoNacimiento.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* ----------- Residencia ----------- */}

        <Form.Item
          label="Departamento de residencia"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            disabled={isDisabled}
            value={departamentoResidencia}
            onSelect={(value) => {
              setDepartamentoResidencia(value);
              fetchProvinciaResidencia(value.slice(0, 2));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDepartamentos.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Provincia de residencia"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            disabled={isDisabled}
            value={provinciaResidencia}
            onSelect={(value) => {
              setProvinciaResidencia(value);
              fetchDistritoResidencia(value.slice(0, 4));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayProvinciaResidencia.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Ciudad de residencia"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            disabled={isDisabled}
            value={distritoResidencia}
            onSelect={(value) => setDistritoResidencia(value)}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDistritoResidencia.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* ----------- Fin residencia ----------- */}

        <Form.Item
          label="Tipo de zona"
          name="idtipozona"
          initialValue={record?.idtipozona || ""}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.zona.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Zona" name="zona" initialValue={record?.zona}>
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item
          label="Tipo de vía"
          name="idtipovia"
          initialValue={record?.idtipovia || ""}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.via.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="via" name="via" initialValue={record?.via}>
          <Input disabled={isDisabled} />
        </Form.Item>

        <Form.Item label="Número" name="numero" initialValue={record?.numero}>
          <Input disabled={isDisabled} />
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DatosPersonales;
