import { Button, Switch, Tooltip } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  MailOutlined,
  EyeOutlined,
  KeyOutlined
} from "@ant-design/icons";

/**
 * createColumns
 * Complemento para mostrar las tablas tanto como en antd y modo responsive
 * @param {Function} showEditModal
 * @param {Function} showDeleteModal
 * @param {Number} page
 */
export function createColumns(
  page = 1,
  showDetails = null,
  showEditModal = null,
  showDeleteModal = null,
  onConfirmMail = null,
  onResetPassword = null,
  onChangeState = null
) {
  return [
    {
      title: "N.º",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },
    {
      title: "DNI",
      dataIndex: "documentoregistro",
      key: "documentoregistro",
      sorter: (a, b) => a.documentoregistro.localeCompare(b.documentoregistro)
    },
    {
      title: "A. Paterno",
      dataIndex: "apellidopaterno",
      key: "apellidopaterno",
      sorter: (a, b) => a.apellidopaterno.localeCompare(b.apellidopaterno),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "A. Materno",
      dataIndex: "apellidomaterno",
      key: "apellidomaterno",
      sorter: (a, b) => a.apellidomaterno.localeCompare(b.apellidomaterno),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Nombres",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Celular",
      dataIndex: "celular",
      key: "celular",
      sorter: (a, b) => a.celular.localeCompare(b.celular),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Correo",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Acciones",
      key: "action",
      render: (record) => (
        <span>
          <Tooltip title="Mostrar detalles">
            <Button icon={<EyeOutlined />} onClick={() => showDetails(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Editar postulante">
            <Button icon={<EditOutlined />} onClick={() => showEditModal(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Activar correo">
            <Button icon={<MailOutlined />} onClick={() => onConfirmMail(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Resetear contraseña, deberá ingresar con su número de DNI">
            <Button icon={<KeyOutlined />} onClick={() => onResetPassword(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Eliminar postulante">
            <Button icon={<DeleteOutlined />} onClick={() => showDeleteModal(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Habilitar/desabilitar postulante">
            <Switch size="small" checked={record.activo} onClick={() => onChangeState(record)} />
          </Tooltip>
        </span>
      )
    }
  ];
}
