import { useState } from "react";
import { useAuth } from "../../utils/useAuth";
import { Spin } from "antd";
import { Switch, Route, Redirect } from "react-router-dom";
import { MenuLateral } from "../../components";
import * as SHARED from "../../utils/shared";
import LayoutAdminHeader from "./LayoutAdminHeader";

function LayoutAdmin({ routes }) {
  const { usuario, isLoading } = useAuth();
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(true);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const closeDrawer = () => {
    setIsDrawerVisible(false);
  };

  const toggleMenuCollapsed = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  if (isLoading) {
    return <Spin tip="Cargando" className="w-full py-60" />;
  }

  if (!usuario) return <Redirect to="/" />;

  return (
    <div className="flex flex-col w-full min-h-screen items-center justify-between">
      <LayoutAdminHeader usuario={usuario} showDrawer={showDrawer} />
      <main className="mb-auto w-full md:flex md:content-between">
        <aside className="hidden md:block">
          <MenuLateral
            isMenuCollapsed={isMenuCollapsed}
            toggleMenuCollapsed={toggleMenuCollapsed}
            isDrawerVisible={isDrawerVisible}
            closeDrawer={closeDrawer}
          />
        </aside>
        <aside className="w-full px-6">
          <LoadRoutes routes={routes} />
        </aside>
      </main>
      <footer className="py-8">
        <p className="text-center font-semibold text-xs text-gray-400">
          {SHARED.TITLE_SISTEMA_SHORT}
        </p>
      </footer>
    </div>
  );
}
export default LayoutAdmin;

/**
 * Carga las rutas definidas en el archivo de rutas.
 * @param {Array} routes
 */
function LoadRoutes(props) {
  const { routes, usuario } = props;
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          exact={route.exact}
          component={(props) => <route.component {...props} usuario={usuario} />}
        />
      ))}
    </Switch>
  );
}
