import { Fragment } from "react";
import { Button, message } from "antd";
import { useHistory } from "react-router-dom";
import * as AppApi from "../../api/sistema";

/**
 * DeletePeriodo
 * @param {Object} user
 * @param {Function} closeModal
 */
function DeletePeriodo({ registro, closeModal }) {
  const history = useHistory();

  const handleDelete = async () => {
    try {
      const params = {
        nombretabla: "pos_periodo",
        nombreid: "idperiodo",
        valorid: registro?.idperiodo
      };

      const res = await AppApi.executeUpdate(params, {
        eliminado: true
      });

      message.success(res.message);
      closeModal();
      history.replace("/app/periodo");
    } catch (err) {
      console.log(err);
      message.error(err.message);
    }
  };

  return (
    <Fragment>
      <p className="mb-5">
        ¿Estás seguro de eliminar el periodo
        <span className="text-red-500"> {registro.denominacion}</span>?
      </p>
      <Button type="danger" block htmlType="submit" onClick={() => handleDelete(registro._id)}>
        Eliminar
      </Button>
    </Fragment>
  );
}
export default DeletePeriodo;
