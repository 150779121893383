import { useState, useEffect } from "react";
import { Form, Input, message, Button, Select, Tooltip } from "antd";
import * as AppApi from "../../api/sistema";
import { getPeriodo } from "../../utils/functions";
import { useAuth } from "../../utils/useAuth";
import { OBJ_ROLES } from "../../utils/shared";

const { Option } = Select;
const idPeriodo = getPeriodo()?.idperiodo || 0;

const DatosPago = ({ record, isDisabled }) => {
  const { rol } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [catalogo, setCatalogo] = useState({
    formapago: [],
    programas_estudio: [],
    modalidadpago: []
  });
  const [informacionPago, setInformacionPago] = useState({
    pagado: false,
    fecha: "",
    nombre: "",
    importe: "",
    concepto: "",
    codigoconcepto: ""
  });
  const [inputInformacionPago, setInputInformacionPago] = useState({
    numerocomprobante: record.numerocomprobante,
    documentoregistrocomprobante: record.documentoregistrocomprobante,
    concepto: record.idmodalidadpago,
    importe: record.importe
  });

  useEffect(() => {
    fetchCatalogo();
    fetchDataToPage(record.documentoregistrocomprobante, record.numerocomprobante);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCatalogo = async () => {
    const res1 = await AppApi.executeQuery({ query: "get_forma_pago" });
    const res2 = await AppApi.executeQuery({ query: "get_all_programas_estudio" });
    const res3 = await AppApi.executeQuery({
      query: "get_modalidad_pago",
      variables: [
        {
          name: "IDPERIODO",
          value: idPeriodo
        }
      ]
    });

    setCatalogo({
      formapago: res1.payload,
      programas_estudio: res2.payload,
      modalidadpago: res3.payload
    });
  };

  const fetchDataToPage = async (documentoregistrocomprobante, numerocomprobante) => {
    try {
      setIsLoading(true);
      const res3 = await AppApi.executeQuery({
        query: "get_detalle_pago_postulante",
        variables: [
          { name: "NUMERODOCUMENTO", value: documentoregistrocomprobante, type: "string" },
          {
            name: "SECUENCIA",
            value: String(numerocomprobante).padStart(7, "0"),
            type: "string"
          }
        ]
      });

      setInformacionPago({
        pagado: Boolean(res3.payload.length),
        fecha: res3.payload[0]?.fechapago,
        nombre: res3.payload[0]?.nombre,
        importe: res3.payload[0]?.importe,
        banco: res3.payload[0]?.banco,
        concepto: res3.payload[0]?.concepto,
        codigoconcepto: res3.payload[0]?.codigoconcepto
      });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onFinish = (values) => {
    sendDataToServer(values);
  };

  // ------- Conexion con api -------
  const sendDataToServer = async (data) => {
    const params = {
      nombretabla: "pos_postulante",
      nombreid: "idpostulante",
      valorid: record?.idpostulante
    };

    AppApi.executeUpdate(params, data)
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const compruebaPago = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      fetchDataToPage(
        inputInformacionPago.documentoregistrocomprobante,
        inputInformacionPago.numerocomprobante
      );
    }, 1000);
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={onFinish}>
      <h1 className="text-azul-700 font-semibold text-base mb-2">DATOS SOBRE EL PAGO</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Form.Item label="Forma pago" name="idformapago" initialValue={record?.idformapago}>
          <Select disabled={isDisabled}>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.formapago.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Importe en soles (S/)" name="importe" initialValue={record?.importe}>
          <Input
            onChange={(e) =>
              setInputInformacionPago({
                ...inputInformacionPago,
                importe: e.target.value
              })
            }
            disabled={isDisabled}
          />
        </Form.Item>

        <Form.Item
          label="Fecha comprobante"
          name="fechacomprobante"
          initialValue={record?.fechacomprobante}
        >
          <Input type="date" disabled={isDisabled} />
        </Form.Item>
      </div>

      <hr className="mt-4" />
      <h1 className="text-azul-700 font-semibold text-base mb-2">COMPROBACIÓN DE PAGO</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <Form.Item
          label={
            <Tooltip title="Los pagos BCP tienen 08 dígitos (llenar con ceros a la izquiera para completar a 08 dígitos). Los pagos en Banco de la Nación y Págalo.pe 07 dígitos. Los pagos de Scotiabank 15 dígitos y sin puntos">
              Número de comprobante {" (ojo)"}
            </Tooltip>
          }
          name="numerocomprobante"
          initialValue={record?.numerocomprobante}
        >
          <Input
            onChange={(e) =>
              setInputInformacionPago({
                ...inputInformacionPago,
                numerocomprobante: e.target.value
              })
            }
            disabled={isDisabled}
          />
        </Form.Item>

        <Form.Item
          label="DNI en comprobante"
          name="documentoregistrocomprobante"
          initialValue={record?.documentoregistrocomprobante}
        >
          <Input
            onChange={(e) =>
              setInputInformacionPago({
                ...inputInformacionPago,
                documentoregistrocomprobante: e.target.value
              })
            }
            disabled={isDisabled}
          />
        </Form.Item>

        <Form.Item label={<>&nbsp;</>}>
          <Button htmlType="submit" onClick={compruebaPago}>
            Comprobar
          </Button>
        </Form.Item>
      </div>

      <h1 className="text-azul-800 font-semibold text-sm my-3">
        Resultados de comprobación de pago:
      </h1>

      {isLoading && <p>Cargando...</p>}

      {!isLoading && (
        <>
          <div className="flex flex-col md:flex-row gap-4">
            <div>
              <p>
                PAGADO:
                {informacionPago.pagado ? (
                  <span className="text-azul-700 font-bold"> SÍ</span>
                ) : (
                  <span className="text-rojo-500 font-bold"> NO</span>
                )}
              </p>
            </div>

            <div>
              <p>
                FECHA: <span className="font-bold">{informacionPago.fecha}</span>
              </p>
            </div>

            <div>
              <p>
                IMPORTE: <span className="font-bold">S/{informacionPago.importe}</span>
              </p>
            </div>
          </div>

          <div className="flex gap-4 my-2">
            <div>
              <p>
                MEDIO: <span className="font-bold">{informacionPago.banco}</span>
              </p>
            </div>

            <div>
              <p>
                NOMBRE: <span className="font-bold">{informacionPago.nombre}</span>
              </p>
            </div>

            <div>
              <p>
                CONCEPTO:{" "}
                <span className="font-bold">
                  {informacionPago.codigoconcepto}-{informacionPago.concepto}
                </span>
              </p>
            </div>
          </div>
        </>
      )}

      <hr className="mt-8" />
      <h1 className="text-azul-700 font-semibold text-base mb-2">Sobre la postulación</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {(rol === OBJ_ROLES.SUPERADMIN || rol === OBJ_ROLES.ADMINISTRADOR) && (
          <Form.Item
            label="Especialidad a la que postula"
            name="idprogramaestudio"
            initialValue={record?.idprogramaestudio || null}
          >
            <Select disabled={isDisabled || rol !== OBJ_ROLES.SUPERADMIN}>
              <Option value="" disabled>
                Seleccione
              </Option>
              {catalogo.programas_estudio.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DatosPago;
