function CardReport(props) {
  const { onClick, disabled, imgSrc, imgAlt, desc } = props;

  // ------ Render -----
  return (
    <button
      className={`px-5 py-3 border-4 border-gray-300 rounded-xl mb-3 bg-white cursor-pointer max-w-xs flex items-center hover:bg-gray-300 `}
      disabled={disabled}
      onClick={onClick}
    >
      <img src={imgSrc} alt={imgAlt} className="w-12 mr-2" />
      <span>{desc}</span>
    </button>
  );
}

export default CardReport;

/*

.card-report {
  padding: 30px;
  border: 4px solid #f7f7fa;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  cursor: pointer;
  max-width: 322px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: rgba($color: #f7f7fa, $alpha: 0.2);
  }

  &.disabled {
    background-color: rgba(239, 239, 239, 0.6);
    cursor: not-allowed;
  }
} */
