import { Input } from "antd";

/**
 * Searcher
 * Filtra datos, necesita una funcion antes onchange
 * @param {String} placeholder
 * @param {Function} onSearch
 * @param {Boolean} enterButton
 * @param {String} className
 */
function Searcher(props) {
  const { placeholder = "", onSearch = null, enterButton = false, className = "" } = props;

  return (
    <div className={"pb-4 " + className}>
      <Input.Search
        allowClear
        placeholder={placeholder}
        enterButton={enterButton}
        onSearch={onSearch}
      />
    </div>
  );
}
export default Searcher;
