import { useState, useEffect } from "react";
import { Card, Input, Modal, notification, Button, Select } from "antd";
import { marcarConstanciaEntregada } from "../../api/ingresante";
// import { useAuth } from "../../utils/useAuth";
import RIngresante from "../../components/Responder/RIngresante";
import * as AppApi from "../../api/sistema";

const { Search } = Input;
const { Option } = Select;

function ConstanciaIngresante() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isConsulting, setIsConsulting] = useState(false);
  const [onSelectPeriodo, setOnSelectPeriodo] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [revisores, setRevisores] = useState([]);
  const [onSelectRevisor, setOnSelectRevisor] = useState("");

  useEffect(() => {
    fetchDataToPage();
  }, []);

  const fetchDataToPage = async () => {
    AppApi.executeQuery({
      query: "get_personales_revisores"
    })
      .then((res) => {
        setRevisores(res.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const periodos = [
    {
      value: "101",
      label: "2024 - CEPU CICLO III"
    },
    {
      value: "97",
      label: "2024 - FASE II"
    },
    {
      value: "98",
      label: "2024 - EXTR. TRASLADOS INTERNOS"
    },
    {
      value: "95",
      label: "2024 - FASE I"
    },
    {
      value: "96",
      label: "2024 - EXTRAORDINARIO"
    },
    {
      value: "99",
      label: "2024 - CEPU CICLO I"
    },
    {
      value: "100",
      label: "2024 - CEPU CICLO II"
    }
  ];

  // const { usuario } = useAuth();

  const handleSearch = async (value) => {
    if (!value.length) return null;

    if (value.length > 60) return null;

    const dniocodigoconstancia = value.length > 8 ? value.slice(-13) : searchValue;

    const body = {
      idperiodo: onSelectPeriodo,
      usuario: onSelectRevisor,
      // usuario: usuario.usuario,
      dniocodigoconstancia
    };

    await marcarConstanciaEntregada(body)
      .then(async (res) => {
        setIsModalVisible(true);
        setModalContent(<RIngresante response={res.payload} />);
      })
      .catch((err) => {
        console.log(err);
        notification.error({ description: err.message });
      })
      .finally(() => {
        setIsConsulting(false);
        setSearchValue("");
      });
  };

  return (
    <div className="p-4 mx-auto text-center w-full">
      <div className="w-full">
        <Card className="mt-8 px-2 rounded-lg max-w-sm mx-auto">
          <div className="font-semibold flex flex-col">
            <span className="mb-2 text-base">
              Marcar entrega por DNI o QR de
              <span className="text-blue-400 font-bold"> CONSTANCIA</span>
            </span>

            <Select
              onSelect={(value) => setOnSelectRevisor(value)}
              defaultValue=""
              size="large"
              className="w-full mt-2"
            >
              <Option value="">NINGUNO</Option>
              {revisores.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>

            <Select
              onSelect={(value) => setOnSelectPeriodo(value)}
              defaultValue=""
              size="large"
              className="w-full "
            >
              <Option value="">NINGUNO</Option>
              {periodos.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>

            <Search
              placeholder="ejem. 789456123"
              className="text-2xl"
              type="text"
              value={searchValue}
              onSearch={handleSearch}
              onChange={(e) => setSearchValue(e.target.value)}
              enterButton
              loading={isConsulting}
              allowClear
              size="large"
            />
          </div>
        </Card>
      </div>

      <Modal
        visible={isModalVisible}
        closable
        onCancel={() => setIsModalVisible(false)}
        destroyOnClose
        footer={
          <Button onClick={() => setIsModalVisible(false)} block type="primary">
            Cerrar
          </Button>
        }
        maskClosable={false}
      >
        {modalContent}
      </Modal>
    </div>
  );
}
export default ConstanciaIngresante;
