import { Button, Switch, Tooltip } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

/**
 * createColumns
 * Complemento para mostrar las tablas tanto como en antd y modo responsive
 * @param {Function} showEditModal
 * @param {Function} onDelete
 * @param {Number} page
 */
export function createColumns(
  page = 1,
  showEditModal = null,
  onDelete = null,
  onChangeState = null,
  periodoFilter
) {
  return [
    {
      title: "N.º",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },
    {
      title: "Examen",
      dataIndex: "periodo",
      key: "periodo",
      filters: periodoFilter.map((periodo) => ({ text: periodo, value: periodo })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record.periodo) {
          return record.periodo.toString() === value.toString();
        }
        return false;
      },
      sorter: (a, b) => a.periodo.localeCompare(b.periodo),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Denominación",
      dataIndex: "denominacion",
      key: "denominacion",
      sorter: (a, b) => a.denominacion.localeCompare(b.denominacion),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Costo",
      dataIndex: "labelcostototal",
      key: "labelcostototal",
      sorter: (a, b) => a.labelcostototal.localeCompare(b.labelcostototal),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Concepto",
      dataIndex: "concepto",
      key: "concepto",
      sorter: (a, b) => a.concepto.localeCompare(b.concepto),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Condición",
      dataIndex: "condicionpago",
      key: "condicionpago",
      sorter: (a, b) => a.condicionpago.localeCompare(b.condicionpago),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Acciones",
      key: "action",
      render: (record) => (
        <span>
          <Tooltip title="Editar">
            <Button icon={<EditOutlined />} onClick={() => showEditModal(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Eliminar">
            <Button icon={<DeleteOutlined />} onClick={() => onDelete(record)} />
          </Tooltip>
          &nbsp;
          <Tooltip title="Habilitar/desabilitar">
            <Switch size="small" checked={record.activo} onClick={() => onChangeState(record)} />
          </Tooltip>
        </span>
      )
    }
  ];
}
