import { useState, useEffect } from "react";
import { Form, Input, Button, message, Select } from "antd";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";
import { TIPO_CATALOGO } from "../../utils/shared";
import { getDataCatalogo, getListaDireccionUgel } from "../../utils/functions";

const { Option } = Select;

function CentroEstudioAddEdit({ record = null, closeModal }) {
  const history = useHistory();
  const [catalogo, setCatalogo] = useState({
    nivelmodalidad: [],
    gestioneducativa: [],
    formacionestudio: [],
    tiposexo: [],
    turno: [],
    tipocentroestudio: [],
    direccionugel: []
  });

  const [arrayDepartamentos, setArrayDepartamentos] = useState([]);

  const [departamentoCentroEstudio, setDepartamentoCentroEstudio] = useState("");
  const [provinciaCentroEstudio, setProvinciaCentroEstudio] = useState("");
  const [distritoCentroEstudio, setDistritoCentroEstudio] = useState("");

  const [arrayProvinciaCentroEstudio, setArrayProvinciaCentroEstudio] = useState([]);
  const [arrayDistritoCentroEstudio, setArrayDistritoCentroEstudio] = useState([]);

  useEffect(() => {
    fetchCatalogo();
    fetchDepartamentos();
  }, []);

  useEffect(() => {
    getDefaults(record);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCatalogo = async () => {
    const nivelmodalidad = await getDataCatalogo(TIPO_CATALOGO.nivelmodalidad);
    const gestioneducativa = await getDataCatalogo(TIPO_CATALOGO.gestioneducativa);
    const formacionestudio = await getDataCatalogo(TIPO_CATALOGO.formacionestudio);
    const tiposexo = await getDataCatalogo(TIPO_CATALOGO.tiposexo);
    const turno = await getDataCatalogo(TIPO_CATALOGO.turno);
    const tipocentroestudio = await getDataCatalogo(TIPO_CATALOGO.tipocentroestudio);
    const direccionugel = await getListaDireccionUgel();

    setCatalogo({
      nivelmodalidad,
      gestioneducativa,
      formacionestudio,
      tiposexo,
      turno,
      tipocentroestudio,
      direccionugel
    });
  };

  const fetchDepartamentos = () => {
    AppApi.executeQuery({
      query: "get_departamentos"
    }).then((data) => {
      setArrayDepartamentos(data.payload);
    });
  };

  const getDefaults = (payload) => {
    if (payload?.idubicaciongeografica) {
      const iddepartamento2 = payload.idubicaciongeografica.slice(0, 2);
      const idprovincia2 = payload.idubicaciongeografica.slice(0, 4);
      const iddistrito2 = payload.idubicaciongeografica;

      fetchProvinciaCentroEstudio(iddepartamento2);
      fetchDistritoCentroEstudio(idprovincia2);

      setDepartamentoCentroEstudio(iddepartamento2);
      setProvinciaCentroEstudio(idprovincia2);
      setDistritoCentroEstudio(iddistrito2);
    }
  };

  /**
   * Información geografica centro estudio
   */
  const fetchProvinciaCentroEstudio = async (iddepartamento) => {
    AppApi.executeQuery({
      query: "get_provincias_by_departamento",
      variables: [{ name: "IDDEPARTAMENTO", value: iddepartamento }]
    }).then((data) => {
      setArrayProvinciaCentroEstudio(data.payload);
    });
  };

  const fetchDistritoCentroEstudio = async (idprovincia) => {
    AppApi.executeQuery({
      query: "get_distritos_by_provincia",
      variables: [{ name: "IDPROVINCIA", value: idprovincia }]
    }).then((data) => {
      setArrayDistritoCentroEstudio(data.payload);
    });
  };

  /**
   * Registra o edita un registro
   */
  const sendDataToServer = async (data) => {
    try {
      let response = null;

      data.idubicaciongeografica = distritoCentroEstudio;

      if (record) {
        const params = {
          nombretabla: "ads_centroestudio",
          nombreid: "idcentroestudio",
          valorid: record?.idcentroestudio
        };

        response = await AppApi.executeUpdate(params, data);
      } else {
        response = await AppApi.executeCreate({ nombretabla: "ads_centroestudio" }, data);
      }

      message.success(response.message);
      history.replace("/app/centroestudio");
      closeModal();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={sendDataToServer}>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <Form.Item
          label="Nombre"
          className="md:col-span-2"
          name="denominacion"
          initialValue={record?.denominacion}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Código modular"
          name="codigo"
          initialValue={record?.codigo}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          className="md:col-span-2"
          label="Dirección"
          name="direccion"
          initialValue={record?.direccion}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Centro poblado"
          name="centropoblado"
          initialValue={record?.centropoblado}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Director"
          name="director"
          initialValue={record?.director}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Nivel / Modalidad"
          name="idnivelmodalidad"
          initialValue={record?.idnivelmodalidad}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.nivelmodalidad.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Tipo de Gestión"
          name="idgestiondducativa"
          initialValue={record?.idgestiondducativa}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.gestioneducativa.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Formación estudio"
          name="idformacionestudio"
          initialValue={record?.idformacionestudio}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.formacionestudio.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Género"
          name="idtiposexo"
          initialValue={record?.idtiposexo}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.tiposexo.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Turno"
          name="idturno"
          initialValue={record?.idturno}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.turno.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Tipo centro estudio"
          name="idtipocentroestudio"
          initialValue={record?.idtipocentroestudio}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.tipocentroestudio.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Dirección Ugel"
          name="iddireccionugel"
          initialValue={record?.iddireccionugel}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.direccionugel.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* ----------- Lugar ubigeo ----------- */}

        <Form.Item
          label="Departamento"
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select
            value={departamentoCentroEstudio}
            onSelect={(value) => {
              setDepartamentoCentroEstudio(value);
              fetchProvinciaCentroEstudio(value.slice(0, 2));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDepartamentos.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Provincia" rules={[{ required: true, message: "El campo es requerido" }]}>
          <Select
            value={provinciaCentroEstudio}
            onSelect={(value) => {
              setProvinciaCentroEstudio(value);
              fetchDistritoCentroEstudio(value.slice(0, 4));
            }}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayProvinciaCentroEstudio.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Ciudad" rules={[{ required: true, message: "El campo es requerido" }]}>
          <Select
            value={distritoCentroEstudio}
            onSelect={(value) => setDistritoCentroEstudio(value)}
          >
            <Option value="" disabled>
              Seleccione
            </Option>
            {arrayDistritoCentroEstudio.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}

export default CentroEstudioAddEdit;
