import { Tag, Button, Switch } from "antd";
import { EditOutlined, DeleteOutlined, KeyOutlined } from "@ant-design/icons";

/**
 * createColumns
 * Complemento para mostrar las tablas tanto como en antd y modo responsive
 * @param {Function} showEditModal
 * @param {Function} showDeleteModal
 * @param {Number} page
 */
export function createColumns(
  page = 1,
  showEditModal = null,
  showDeleteModal = null,
  onChangeState = null,
  onResetPassword = null
) {
  return [
    {
      title: "N.º",
      key: "index",
      render: (value, item, index) => (page - 1) * 10 + index + 1
    },
    {
      title: "Usuario",
      dataIndex: "usuario",
      key: "usuario",
      sorter: (a, b) => a.usuario.localeCompare(b.usuario)
    },
    {
      title: "DNI",
      dataIndex: "documentoregistro",
      key: "documentoregistro",
      sorter: (a, b) => a.documentoregistro.localeCompare(b.documentoregistro)
    },
    {
      title: "Apellidos",
      dataIndex: "apellido",
      key: "apellido",
      sorter: (a, b) => a.apellido.localeCompare(b.apellido),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Nombres",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Periodos",
      dataIndex: "periodos",
      key: "periodos",
      sorter: (a, b) => a.periodos.localeCompare(b.periodos),
      render: (periodos) => {
        if (!periodos?.length) {
          return <p>Todos</p>;
        } else {
          return <p>{periodos}</p>;
        }
      }
    },
    {
      title: "Rol",
      dataIndex: "rol",
      key: "rol",
      render: (rol) => {
        let color = "";
        let text = "";

        switch (rol) {
          case "administrador":
            color = "green";
            text = "Administrador";
            break;
          case "organizador":
            color = "blue";
            text = "Organizador";
            break;
          case "segundaespecialidad":
            color = "skyblue";
            text = "Segunda especialidad";
            break;
          case "directivo":
            color = "red";
            text = "Nivel Directivo";
            break;
          case "superadmin":
            color = "cyan";
            text = "SuperAdmin";
            break;
          case "registrador":
            color = "gold";
            text = "Registrador";
            break;
          case "especialista":
            color = "lime";
            text = "Especialista de Sistemas";
            break;
          default:
            break;
        }
        return (
          <Tag color={color} key={rol}>
            {text}
          </Tag>
        );
      },
      sorter: (a, b) => {
        return a.role.length - b.role.length;
      },
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "Acciones",
      key: "action",
      render: (record) => (
        <span>
          <Button icon={<EditOutlined />} onClick={() => showEditModal(record)} />
          &nbsp;
          <Button icon={<DeleteOutlined />} onClick={() => showDeleteModal(record)} />
          &nbsp;
          <Button icon={<KeyOutlined />} onClick={() => onResetPassword(record)} />
          &nbsp;
          <Switch size="small" checked={record.activo} onClick={() => onChangeState(record)} />
        </span>
      )
    }
  ];
}
