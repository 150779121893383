function ExamLabel(props) {
  const {
    firstTitle = "First title",
    secondTitle = "",
    mainTitle = "",
    bgMainTitle = "bg-red-500",
    style = null
  } = props;
  return (
    <div className="text-center mt-7 mb-7">
      <h2 className="font-bold text-lg mb-2">{firstTitle}</h2>
      <h4 className="font-semibold text-base text-gray-400 mb-3">{secondTitle}</h4>
      <div
        className={"w-full mx-auto text-white font-bold px-4 py-5 " + bgMainTitle}
        role="alert"
        style={style}
      >
        <p className="text-lg">{mainTitle}</p>
      </div>
    </div>
  );
}

export default ExamLabel;
