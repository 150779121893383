import { Button } from "antd";

/**
 * ButtonDownload
 * ---------------
 * @param {Function} onDownload
 * @param {ReactElement} icon
 * @param {String} buttonTitle
 * @param {String} fileName DEBE TENER LA EXTENSIÓN (ej: .pdf)
 * @param {String} fileFormat
 * @returns
 */
function ButtonDownload(props) {
  const {
    onDownload = null,
    icon = null,
    buttonTitle = "",
    fileName = "",
    type = "default",
    className = "",
  } = props;

  const functionDownload = async () => {
    const blob = await onDownload();

    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${fileName}`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <Button
      className={className}
      onClick={functionDownload}
      type={type}
      icon={icon}
    >
      {buttonTitle}
    </Button>
  );
}

export default ButtonDownload;
