import { useState } from "react";
import { Result, Button, message } from "antd";
import { UploadDeleteDocument, UploadImage } from "../../components";
import {
  ScissorOutlined,
  ThunderboltOutlined,
  ReloadOutlined,
  DownloadOutlined
} from "@ant-design/icons";
import ButtonDownload from "../../components/ButtonDownload";
import * as AppApi from "../../api/sistema";

export default function RProcesarFotografia({
  fotografia,
  title,
  requisito,
  identidad,
  documentoregistro,
  requisitoXPersona
}) {
  const [loaddingProcesamiento, setLoaddingProcesamiento] = useState(false);

  const params = {
    nombretabla: "ope_entidad",
    nombreid: "identidad",
    valorid: identidad
  };

  const recortarFotografia = async () => {
    AppApi.executeUpdate(params, { fotoapprecorte: true })
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });

    setLoaddingProcesamiento(true);
    console.log("recortar fotografia", fotografia);
    const result = await AppApi.recortarFoto({
      url_imagen: fotografia
    });
    setLoaddingProcesamiento(false);
    console.log(result);
  };

  const quitarFondoFotografia = async () => {
    AppApi.executeUpdate(params, { fotoappquitafondo: true })
      .then((res) => {
        message.success(res.message);
      })
      .catch((err) => {
        message.error(err.message);
      });

    setLoaddingProcesamiento(true);
    console.log("quitar fondo fotografia", fotografia);
    const result = await AppApi.quitaFondoFoto({
      url_imagen: fotografia
    });
    setLoaddingProcesamiento(false);
    console.log(result);
  };

  const restablecerFotografia = async () => {
    setLoaddingProcesamiento(true);
    console.log("quitar fondo fotografia", fotografia);
    const result = await AppApi.restablecerFoto({
      url_imagen: fotografia
    });
    setLoaddingProcesamiento(false);
    console.log(result);
  };

  const downloadFile = (filepath) => {
    return new Promise((resolve) => {
      resolve(AppApi.previewFile(filepath));
    });
  };

  if (!fotografia) {
    return <Result status="error" title="Lo sentimos" subTitle="No encontramos al postulante" />;
  } else {
    return (
      <div className="text-center">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div>
            <h1 className="font-bold text-base text-azul-700 mb-2">FOTOGRAFÍA ORIGINAL</h1>
            <img
              className="rounded"
              src={fotografia}
              alt={`Foto original de ${title}`}
              width={320}
              height={360}
            />

            <div className="flex mt-3 gap-2">
              <div className="flex">
                <UploadDeleteDocument
                  action="/api/core/panel/upload/requisito/entidad"
                  documentoregistro={documentoregistro}
                  abreviatura={requisito.abreviatura}
                  formato={requisito.formato}
                  idrequisito={requisito.idrequisito}
                  identidad={identidad}
                />
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    window.location.reload();
                  }}
                ></Button>
              </div>

              <ButtonDownload
                icon={<DownloadOutlined />}
                fileName={`${documentoregistro}_${requisito.abreviatura}.${requisito.formato}`}
                onDownload={() =>
                  downloadFile(
                    "/" +
                      requisitoXPersona.find((i) => i.idrequisito === requisito.idrequisito)?.ruta
                  )
                }
                buttonTitle="Descargar"
              />
            </div>
          </div>

          <div className="flex flex-col gap-2 lg:max-w-sm">
            <Button className="mt-1" icon={<ScissorOutlined />} onClick={recortarFotografia}>
              Recortar
            </Button>
            <Button className="mt-1" icon={<ThunderboltOutlined />} onClick={quitarFondoFotografia}>
              Quitar fondo
            </Button>
            <Button className="mt-1" icon={<ReloadOutlined />} onClick={restablecerFotografia}>
              Restablecer
            </Button>
          </div>

          <div>
            <h1 className="font-bold text-base text-azul-700 mb-2">FOTOGRAFÍA PROCESADA</h1>
            {!loaddingProcesamiento && (
              <img
                className="rounded"
                src={`https://admision.unjbg.edu.pe:8444/static/${documentoregistro}_FTR.jpg`}
                alt={`Fotografía procesada de ${documentoregistro}`}
                width={320}
                height={360}
              />
            )}

            <div className="mt-3">
              <UploadImage
                imageName={`${documentoregistro}_${requisito.abreviatura}.${requisito.formato}`}
                identidad={identidad}
              />
              {/* <Button onClick={onDownloadFotoProcesada}>Descargar</Button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
