import { Button, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { uploadProps } from "../../utils/functions";

function UploadDeleteDocument(props) {
  // 1ra parte
  const {
    title,
    action,
    documentoregistro,
    abreviatura,
    formato,
    idrequisito,
    idperiodorequisito,
    idpostulante,
    identidad,
    disabled,
    reload,
    setReload
  } = props;

  const UploadClientDocument = ({
    denominacion,
    extension,
    tamanio,
    idrequisito,
    identidad,
    idperiodorequisito,
    idpostulante
  }) => {
    // 3ra parte
    return uploadProps({
      name: "attached",
      action,
      data: {
        denominacion,
        extension,
        tamanio,
        idrequisito,
        identidad,
        idperiodorequisito,
        idpostulante
      },
      makeReload: () => setReload(!reload)
    });
  };

  return (
    <div className="mb-4 text-left">
      {title && <span className="inline-block pb-2 font-semibold text-xs">{title}</span>}
      <div className="flex justify-between">
        {/* 2 parte */}
        <Upload
          {...UploadClientDocument({
            denominacion: `${documentoregistro}_${abreviatura}.${formato}`,
            extension: formato,
            tamanio: "",
            idrequisito,
            identidad,
            idperiodorequisito,
            idpostulante
          })}
          accept={"." + formato}
          maxCount={1}
        >
          <Button icon={<UploadOutlined />} disabled={disabled}>
            Subir archivo
          </Button>
        </Upload>
      </div>
    </div>
  );
}

export default UploadDeleteDocument;
