import React from "react";
import { Menu } from "antd";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import {
  UserOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  FundProjectionScreenOutlined,
  TeamOutlined,
  BuildOutlined,
  HddOutlined,
  FileDoneOutlined,
  SettingOutlined,
  NodeExpandOutlined,
  GroupOutlined,
  OrderedListOutlined,
  SnippetsOutlined,
  EuroOutlined,
  BookOutlined,
  PauseOutlined,
  BankOutlined
} from "@ant-design/icons";
import { OBJ_ROLES } from "../../utils/shared";

/**
 * MenuItems
 * @param {Boolean} isMenuCollapsed
 * @param {Function} closeDrawer
 */
function MenuItems({ isMenuCollapsed = false, closeDrawer = null }) {
  const { pathname } = useLocation();
  const { rol } = useAuth();

  return (
    <Menu selectedKeys={[pathname]} mode="inline" theme="light" inlineCollapsed={isMenuCollapsed}>
      {rol !== OBJ_ROLES.REGISTRADOR && (
        <Menu.Item onClick={closeDrawer} key="/app" icon={<HomeOutlined />}>
          <Link to="/app">Inicio</Link>
        </Menu.Item>
      )}

      {rol === OBJ_ROLES.REGISTRADOR && (
        <Menu.Item onClick={closeDrawer} key="/app/postulante" icon={<FolderOpenOutlined />}>
          <Link to="/app/postulante">Postulante</Link>
        </Menu.Item>
      )}

      {rol === OBJ_ROLES.SEGUNDA_ESPECIALIDAD && (
        <>
          <Menu.Item onClick={closeDrawer} key="/app/postulante" icon={<FolderOpenOutlined />}>
            <Link to="/app/postulante">Postulante</Link>
          </Menu.Item>

          <Menu.Item
            onClick={closeDrawer}
            key="/app/reporte"
            icon={<FundProjectionScreenOutlined />}
          >
            <Link to="/app/reporte">Reportes</Link>
          </Menu.Item>
        </>
      )}

      {rol === OBJ_ROLES.DIRECTIVO && (
        <>
          <Menu.Item onClick={closeDrawer} key="/app/constancia" icon={<FileDoneOutlined />}>
            <Link to="/app/constancia">Constancias</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/postulante" icon={<FolderOpenOutlined />}>
            <Link to="/app/postulante">Postulante</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/ingresante" icon={<HddOutlined />}>
            <Link to="/app/ingresante">Ingresantes</Link>
          </Menu.Item>

          <Menu.Item
            onClick={closeDrawer}
            key="/app/reporte"
            icon={<FundProjectionScreenOutlined />}
          >
            <Link to="/app/reporte">Reportes</Link>
          </Menu.Item>
        </>
      )}

      {rol === OBJ_ROLES.ORGANIZADOR && (
        <>
          <Menu.Item onClick={closeDrawer} key="/app/postulante" icon={<FolderOpenOutlined />}>
            <Link to="/app/postulante">Postulante</Link>
          </Menu.Item>
        </>
      )}

      {rol === OBJ_ROLES.ESPECIALISTA_SISTEMAS && (
        <>
          <Menu.Item onClick={closeDrawer} key="/app/persona" icon={<TeamOutlined />}>
            <Link to="/app/persona">Personas</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/postulante" icon={<FolderOpenOutlined />}>
            <Link to="/app/postulante">Postulante</Link>
          </Menu.Item>

          <Menu.Item
            onClick={closeDrawer}
            key="/app/reporte"
            icon={<FundProjectionScreenOutlined />}
          >
            <Link to="/app/reporte">Reportes</Link>
          </Menu.Item>
        </>
      )}

      {(rol === OBJ_ROLES.SUPERADMIN || rol === OBJ_ROLES.ADMINISTRADOR) && (
        <>
          <Menu.Item onClick={closeDrawer} key="/app/persona" icon={<TeamOutlined />}>
            <Link to="/app/persona">Personas</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/postulante" icon={<FolderOpenOutlined />}>
            <Link to="/app/postulante">Postulante</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/constancia" icon={<FileDoneOutlined />}>
            <Link to="/app/constancia">Constancias</Link>
          </Menu.Item>

          <Menu.Item
            onClick={closeDrawer}
            key="/app/reporte"
            icon={<FundProjectionScreenOutlined />}
          >
            <Link to="/app/reporte">Reportes</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/usuario" icon={<UserOutlined />}>
            <Link to="/app/usuario">Personal</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/ingresante" icon={<HddOutlined />}>
            <Link to="/app/ingresante">Ingresantes</Link>
          </Menu.Item>
        </>
      )}

      {rol === OBJ_ROLES.SUPERADMIN && (
        <Menu.SubMenu title="Mantenimiento" key="mantenimiento" icon={<SettingOutlined />}>
          <Menu.Item onClick={closeDrawer} key="/app/periodo" icon={<BuildOutlined />}>
            <Link to="/app/periodo">Exámenes</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/proceso" icon={<NodeExpandOutlined />}>
            <Link to="/app/proceso">Procesos</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/catalogo" icon={<GroupOutlined />}>
            <Link to="/app/catalogo">Catálogo</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/requisito" icon={<OrderedListOutlined />}>
            <Link to="/app/requisito">Requisito</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/requisitoexamen" icon={<SnippetsOutlined />}>
            <Link to="/app/requisitoexamen">Requisito examen</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/modalidadpago" icon={<EuroOutlined />}>
            <Link to="/app/modalidadpago">Modalidad pago</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/programaestudio" icon={<BookOutlined />}>
            <Link to="/app/programaestudio">Programa estudio</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/canal" icon={<PauseOutlined />}>
            <Link to="/app/canal">Canal</Link>
          </Menu.Item>

          <Menu.Item onClick={closeDrawer} key="/app/centroestudio" icon={<BankOutlined />}>
            <Link to="/app/centroestudio">Centro estudio</Link>
          </Menu.Item>
        </Menu.SubMenu>
      )}

      {/*
      <Menu.Item onClick={closeDrawer} key="/app/configuracion" icon={<SettingOutlined />}>
        <Link to="/app/configuracion">Confirguración</Link>
      </Menu.Item> */}
    </Menu>
  );
}

export default MenuItems;
