import { Fragment, useState, useEffect } from "react";
import { notification, Button, Modal, message } from "antd";
import { TitleHeader, Searcher, TableAntd } from "../../components";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { executeUpdate } from "../../api/sistema";
import { createColumns } from "./formatTable";
import CatalogoAddEdit from "./CatalogoAddEdit";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";
import { filterRepeatedAndReturnUniqueValues } from "../../utils/functions";

const Catalogo = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const [reload, setReload] = useState(false);
  const [totalData, setTotalData] = useState(0);
  const [dataToTable, setDataToTable] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterTable, setFilterTable] = useState(null);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [widthModal, setWidthModal] = useState(500);

  const history = useHistory();

  const fetchDataToTable = async () => {
    setIsLoading(true);

    AppApi.executeQuery({
      query: "get_total_lista_catalogo"
    })
      .then((res) => {
        setDataToTable(res.payload);
        setTotalData(res.payload.length);
      })
      .catch((err) => {
        notification.error({
          message: "Problemas con la solicitud",
          description: err.message
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataToTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pageSize, page]);

  const showAddModal = async () => {
    setModalTitle("Agregar catalogo");
    setWidthModal(500);
    setModalContent(<CatalogoAddEdit record={null} closeModal={closeModal} />);
    setIsModalVisible(true);
  };

  const showEditModal = async (record) => {
    setModalTitle("Editar catalogo");
    setWidthModal(500);
    setModalContent(<CatalogoAddEdit record={record} closeModal={closeModal} />);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const onDelete = async (record) => {
    Modal.confirm({
      title: `¿Estás seguro de eliminar el valor '${record.denominacion}?'`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, eliminar",
      cancelText: "Cancelar",
      async onOk() {
        try {
          await executeUpdate(
            {
              nombretabla: "ads_catalogo",
              nombreid: "idcatalogo",
              valorid: record.idcatalogo
            },
            { eliminado: true }
          );

          message.success("Registro eliminado satisfactoriamente");
          history.replace("/app/catalogo");
        } catch (err) {
          message.error(err.message);
        }
      }
    });
  };

  const search = (value) => {
    const tempFilterTable = dataToTable.filter((o) =>
      Object.keys(o).some((k) => String(o[k]).toLowerCase().includes(value.toLowerCase()))
    );

    setFilterTable(tempFilterTable);
  };

  const tipoFilter = filterRepeatedAndReturnUniqueValues(dataToTable, "categoria");

  const onChangeState = async (record) => {
    const activateMsg = record.activo ? "desactivar" : "activar";

    Modal.confirm({
      title: `¿Estás seguro de ${activateMsg} el valor '${record.denominacion}?'`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, " + activateMsg,
      cancelText: "Cancelar",
      async onOk() {
        try {
          const response = await executeUpdate(
            {
              nombretabla: "ads_catalogo",
              nombreid: "idcatalogo",
              valorid: record.idcatalogo
            },
            { activo: !record.activo }
          );

          message.success(response.message);
          history.replace("/app/catalogo");
        } catch (err) {
          message.error(err.message);
        }
      }
    });
  };

  const columnsToTable = createColumns(
    page,
    pageSize,
    showEditModal,
    onDelete,
    onChangeState,
    tipoFilter
  );

  return (
    <Fragment>
      <TitleHeader
        title={
          <span className="flex gap-2 items-center">
            <h1 className="font-bold">Catálogo ({totalData})</h1>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => setReload(!reload)}
            ></Button>
          </span>
        }
        buttonTitle="Nuevo valor"
        onButtonTitleHeaderClick={showAddModal}
      />

      <Searcher onSearch={search} placeholder="Busca un valor de catálogo" />

      <TableAntd
        columns={columnsToTable}
        dataSource={filterTable == null ? dataToTable : filterTable}
        isLoading={isLoading}
        setPageSize={setPageSize}
        setPage={setPage}
        pageSize={pageSize}
      />

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        maskClosable={false}
        width={widthModal}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
};

export default Catalogo;
