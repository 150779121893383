import { Fragment, useState, useEffect } from "react";
import { notification, Button, Modal, message } from "antd";
import { TitleHeader, Searcher, TableAntd } from "../../components";
import { ReloadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getPersonas, executeUpdate } from "../../api/sistema";
import { createColumns } from "./formatTable";
import PersonaDetail from "./PersonaDetail";
import PersonaAddEdit from "./PersonaAddEdit";
import PersonaRegister from "./PersonaRegister";
import PersonaDelete from "./PersonaDelete";
import PersonaResetPassword from "./PersonaResetPassword";
import PersonaConfirmarCorreo from "./PersonaConfirmarCorreo";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";

function Persona() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [dataToTable, setDataToTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [reload, setReload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [widthModal, setWidthModal] = useState(1000);

  const history = useHistory();

  const fetchDataToTable = async () => {
    setIsLoading(true);

    getPersonas(`?search=${search}&take=${pageSize}&page=${page}&`)
      .then((res) => {
        setDataToTable(res.payload.data);
        setTotalData(res.payload.count);
      })
      .catch((err) => {
        notification.error({
          message: "Problemas con la solicitud",
          description: err.message
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchDataToTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pageSize, page, search]);

  const showDetails = (record) => {
    fetchDatosFichaPersona(record);
  };

  const fetchDatosFichaPersona = async (record) => {
    const res1 = await AppApi.executeQuery({
      query: "get_reporte_ficha_persona_datos",
      variables: [{ name: "IDENTIDAD", value: record.identidad, type: "string" }]
    });

    const res2 = await AppApi.executeQuery({
      query: "get_reporte_ficha_persona_postulaciones",
      variables: [{ name: "IDENTIDAD", value: record.identidad, type: "string" }]
    });

    setModalTitle("Ficha de persona");
    setWidthModal(1280);
    setModalContent(
      <PersonaDetail
        closeModal={closeModal}
        persona={res1.payload[0]}
        postulaciones={res2.payload}
      />
    );
    setIsModalVisible(true);
  };

  const showAddModal = async () => {
    setModalTitle("Agregar persona");
    setWidthModal(1000);
    setModalContent(<PersonaRegister closeModal={closeModal} />);
    setIsModalVisible(true);
  };

  const showEditModal = async (record) => {
    const res1 = await AppApi.executeQuery({
      query: "get_reporte_ficha_persona_datos",
      variables: [{ name: "IDENTIDAD", value: record.identidad, type: "string" }]
    });

    setModalTitle("Editar persona");
    setWidthModal(1000);
    setModalContent(<PersonaAddEdit closeModal={closeModal} record={res1.payload[0]} />);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const onConfirmMail = async (record) => {
    setModalTitle("Activar correo");
    setWidthModal(500);
    setIsModalVisible(true);
    setModalContent(<PersonaConfirmarCorreo closeModal={closeModal} record={record} />);
  };

  const onResetPassword = async (record) => {
    setModalTitle("Resetear contraseña");
    setWidthModal(500);
    setIsModalVisible(true);
    setModalContent(<PersonaResetPassword closeModal={closeModal} record={record} />);
  };

  const showDeleteModal = async (record) => {
    setModalTitle("Eliminar persona");
    setWidthModal(500);
    setIsModalVisible(true);
    setModalContent(<PersonaDelete closeModal={closeModal} record={record} />);
  };

  const onChangeState = async (record) => {
    const activateMsg = record.activo ? "desactivar" : "activar";

    Modal.confirm({
      title: `¿Estás seguro de ${activateMsg} al usuario '${record.nombre}?'`,
      icon: <ExclamationCircleOutlined />,
      okText: "Sí, " + activateMsg,
      cancelText: "Cancelar",
      async onOk() {
        try {
          const response = await executeUpdate(
            {
              nombretabla: "ope_entidad",
              nombreid: "identidad",
              valorid: record.identidad
            },
            { activo: !record.activo }
          );

          message.success(response.message);
          history.replace("/app/persona");
        } catch (err) {
          message.error(err.message);
        }
      }
    });
  };

  const columnsToTable = createColumns(
    page,
    showDetails,
    showEditModal,
    showDeleteModal,
    onConfirmMail,
    onResetPassword,
    onChangeState
  );

  return (
    <Fragment>
      <TitleHeader
        title={
          <span className="flex gap-2 items-center">
            <h1 className="font-bold">Personas ({totalData})</h1>
            <Button
              size="small"
              icon={<ReloadOutlined />}
              onClick={() => setReload(!reload)}
            ></Button>
          </span>
        }
        buttonTitle="Nueva persona"
        onButtonTitleHeaderClick={showAddModal}
      />

      <Searcher onSearch={(value) => setSearch(value)} placeholder="Busca a una persona" />

      <TableAntd
        columns={columnsToTable}
        dataSource={dataToTable}
        isLoading={isLoading}
        total={totalData}
        setPageSize={setPageSize}
        setPage={setPage}
        pageSize={pageSize}
      />

      <Modal
        title={modalTitle}
        visible={isModalVisible}
        footer={null}
        onCancel={closeModal}
        destroyOnClose
        maskClosable={false}
        width={widthModal}
      >
        {modalContent}
      </Modal>
    </Fragment>
  );
}

export default Persona;
