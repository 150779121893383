import { useState } from "react";
import { Input } from "antd";

function InputAntd(props) {
  const {
    type,
    onChange,
    maxLength,
    regex,
    addonBefore,
    className,
    displayedValue,
    disabled = false,
  } = props;
  const [inputValue, setInputValue] = useState(displayedValue);

  return (
    <Input
      type={type}
      value={inputValue}
      defaultValue={inputValue}
      addonBefore={addonBefore}
      className={className}
      disabled={disabled}
      onChange={(e) => {
        if (maxLength) {
          e.target.value = e.target.value.slice(0, maxLength);
        }

        if (regex && regex !== "" && e.target.value.length > 0) {
          if (!new RegExp(regex, "g").test(e.target.value)) {
            return;
          }
        }

        setInputValue(e.target.value);

        onChange(e);
      }}
    />
  );
}

export default InputAntd;
