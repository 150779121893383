/**
 * PERIODO API
 * Funciones para manejar peticiones con el servidor
 */

import * as HTTP_REQUEST from "./api";
import { handleResponse } from "../utils/functions";

const PATH_MODEL = "/api/core/periodo";

export const addOne = async (body) => {
  const response = await HTTP_REQUEST.post(PATH_MODEL, body);
  return handleResponse(response);
};

export const updateOne = async (body, recordId) => {
  const response = await HTTP_REQUEST.put(PATH_MODEL, body, recordId);
  return handleResponse(response);
};
