import { useState, useEffect } from "react";
import { Form, Button, message, Select } from "antd";
import * as AppApi from "../../api/sistema";
import { useHistory } from "react-router-dom";
import { TIPO_CATALOGO } from "../../utils/shared";
import { getListaPeriodos, getDataCatalogo, getListaRequisito } from "../../utils/functions";

const { Option } = Select;

function RequisitoAddEdit({ record = null, closeModal }) {
  const history = useHistory();
  const [catalogo, setCatalogo] = useState({
    periodo: [],
    modalidadingreso: [],
    requisito: []
  });

  useEffect(() => {
    fetchCatalogo();
  }, []);

  const fetchCatalogo = async () => {
    const periodo = await getListaPeriodos();
    const requisito = await getListaRequisito();
    const modalidadingreso = await getDataCatalogo(TIPO_CATALOGO.modalidadingreso);

    console.log(periodo);

    setCatalogo({
      periodo,
      modalidadingreso,
      requisito
    });
  };

  /**
   * Registra o edita un registro
   */
  const sendDataToServer = async (data) => {
    try {
      let response = null;

      if (record) {
        const params = {
          nombretabla: "pos_periodorequisito",
          nombreid: "idperiodorequisito",
          valorid: record?.idperiodorequisito
        };

        response = await AppApi.executeUpdate(params, data);
      } else {
        response = await AppApi.executeCreate({ nombretabla: "pos_periodorequisito" }, data);
      }

      message.success(response.message);
      history.replace("/app/requisitoexamen");
      closeModal();
    } catch (error) {
      message.error(error.message);
    }
  };

  return (
    <Form layout="vertical" initialValues={{ remember: true }} onFinish={sendDataToServer}>
      <div className="grid grid-cols-1 gap-4">
        <Form.Item
          label="Examen"
          name="idperiodo"
          initialValue={record?.idperiodo}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.periodo.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Modalidad ingreso"
          name="idmodalidadingreso"
          initialValue={record?.idmodalidadingreso}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.modalidadingreso.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Requisito"
          name="idrequisito"
          initialValue={record?.idrequisito}
          rules={[{ required: true, message: "El campo es requerido" }]}
        >
          <Select>
            <Option value="" disabled>
              Seleccione
            </Option>
            {catalogo.requisito.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.label} - ({item.formato})
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="float-right my-4">
          Guardar cambios
        </Button>
      </Form.Item>
    </Form>
  );
}

export default RequisitoAddEdit;
