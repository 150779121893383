import { Spin } from "antd";
import LogoAdmisionIcon from "../../assets/LogoAdmisionIcon";

function Loading() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="h-full text-center">
        <LogoAdmisionIcon />
        <Spin className="w-full mt-2" size="small" tip="cargando..." />
      </div>
    </div>
  );
}

export default Loading;
