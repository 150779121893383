import React, { useState } from "react";
import { BASE_PATH } from "../../api/api";
import { ACCESS_TOKEN } from "../../api/auth";
import * as AppApi from "../../api/sistema";
import { message } from "antd";

function UploadImage({
  imageName = "", // 88888888_FTR.jpg
  identidad = "" // mongoid
}) {
  const [isLoading, setIsLoading] = useState(false);

  const imageUrl = "https://admision.unjbg.edu.pe:8444/static/" + imageName;
  const uploadUrl = BASE_PATH + "/api/core/panel/upload/requisito/entidad";

  const params = {
    nombretabla: "ope_entidad",
    nombreid: "identidad",
    valorid: identidad
  };

  const uploadImage = async () => {
    setIsLoading(true);

    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const formData = new FormData();
      formData.append("denominacion", imageName);
      formData.append("extension", "jpg");
      formData.append("tamanio", "");
      formData.append("idrequisito", "2");
      formData.append("identidad", identidad);
      formData.append("idperiodorequisito", "undefined");
      formData.append("idpostulante", "undefined");
      formData.append("attached", blob, imageName);

      const result = await fetch(uploadUrl, {
        method: "POST",
        headers: {
          Authorization: ACCESS_TOKEN,
          Accept: "*/*",
          "X-Requested-With": "XMLHttpRequest"
        },
        body: formData
      });

      const data = await result.json();
      console.log("Upload success:", data);

      AppApi.executeUpdate(params, { fotoprocesada: true })
        .then((res) => {
          message.success(res.message);
        })
        .catch((err) => {
          message.error(err.message);
        });

      window.location.reload();
    } catch (error) {
      console.error("Upload error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button
        onClick={uploadImage}
        disabled={isLoading}
        class="border hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
      >
        <svg
          class="fill-current w-4 h-4 mr-2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M10 2l6 6H4l6-6zm0 2.414L7.414 7h5.172L10 4.414zM4 12h12v2H4v-2zm0 4h12v2H4v-2z" />
        </svg>
        <span>{isLoading ? "Estableciendo..." : "Establecer"}</span>
      </button>
    </div>
  );
}

export default UploadImage;
